import { InputProps } from './MyInput.props';

import styled, { css } from 'styled-components';

import { SecondaryText, theme } from '@/styles';

export const MyInputWrapper = styled.div<Pick<InputProps, 'error' | 'showErrorMessage'>>`
  position: relative;
  ${({ error, showErrorMessage }) =>
    error &&
    showErrorMessage &&
    css`
      border-color: ${theme.colors.red.red_light};
    `};
  width: inherit;
  transition: margin 0.3s ease;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 9px;

  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 140%;

  &:focus :focus-visible {
    & svg {
      & path {
        stroke: ${theme.colors.white.white_100};
      }
    }
  }

  ${(props) => {
    if (props.itemType === 'checkbox') {
      return css`
        flex-direction: row-reverse;
        justify-content: flex-end;
      `;
    }
  }};

  /* cursor: pointer; */
  @media screen and (max-width: 1024px) {
    font-size: 17px;
  }

  @media screen and (max-width: 360px) {
    gap: 3px;
  }
`;

export const Input = styled.input<Pick<InputProps, 'error'> & { isIframe: boolean; iframeTheme: string }>`
  width: 100%;
  padding: 12px 46px 12px 16px;

  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  background: transparent;

  border-radius: 10px;
  color: ${(props) => (props.disabled ? theme.colors.white.white_10 : theme.colors.text.white_30)};
  transition: all 0.3s;
  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => {
    if (props.error) {
      return theme.colors.red.red_light;
    } else if (props.disabled) {
      return theme.colors.white.white_10;
    } else if (props.isIframe && props.iframeTheme === 'light') {
      return theme.colors.blue.blue_dark;
    } else {
      return theme.colors.text.white_30;
    }
  }};

  ${(props) => {
    if (props.type === 'checkbox') {
      return css`
        width: min-content;
      `;
    }
  }};

  @media (max-width: 480px) {
    font-size: 15px;
  }

  ::placeholder {
    color: ${(props) =>
      props.disabled
        ? theme.colors.white.white_30
        : props.isIframe && props.iframeTheme === 'light'
        ? theme.colors.blue.blue_dark
        : theme.colors.text.white_30};
  }

  &:hover {
    border-color: ${(props) =>
      props.disabled
        ? theme.colors.white.white_10
        : props.isIframe && props.iframeTheme === 'light'
        ? theme.colors.white.white_70
        : theme.colors.text.white_100};
    border-color: ${(props) => props.error && theme.colors.red.red_dark};
  }

  &:focus,
  :focus-visible {
    color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light' ? theme.colors.blue.blue_dark : theme.colors.white.white_100};
    outline-color: inherit;
  }
`;

export const ErrorText = styled.span`
  left: 0;
  display: none;
  margin-top: 8px;
  color: ${theme.colors.red.red_light};
  font-size: 17px;
  font-weight: 400;
`;
export const PasswordIconWrapper = styled.span`
  position: absolute;
  top: 55%;
  right: 3%;
  cursor: pointer;
`;

export const MyLabelText = styled(SecondaryText)<Pick<InputProps, 'required' | 'error'>>`
  color: ${theme.colors.white.white_100};
  ${(props) =>
    props.required &&
    css`
      &:after {
        content: '*';
        padding: 0 2px;
      }
    `}
  ${(props) =>
    props.error &&
    css`
      color: ${theme.colors.red.red_light};
    `}
    transition: color 0.3s ease;
`;
