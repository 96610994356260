import { InDev } from './App.styled';
import ScrollToTop from './components/ScrollToTop';
import FullScreenLoader from './components/Shared/FullScreenLoader/FullScreenLoader';
import AddMobile from './components/Shared/Modals/AddMobile/AddMobile';
import { ModalType } from './store/slices/modalsSlice';

import { Suspense, useEffect } from 'react';

import { Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import {
  AddVideo,
  CreatePlaylist,
  Login,
  Notification,
  Registration,
  RemoveVideo,
  ResendEmail,
  ResetPassword,
  VerifyInfo,
} from '@/components';
import { DynamicThemeProvider, RequireAuth } from '@/hoc';
import { useActions, useAppSelector } from '@/hooks';
import { EmbedLayout, MainLayout } from '@/layouts';
import {
  ActivateLazy,
  LendingLazy,
  BalancePage,
  NotFoundPage,
  Password,
  PersonalDataPolicy,
  Policy,
  PrivacyPolicy,
  Profile,
  RedirectRegisterSocialPage,
  ReferalProgramPage,
  ReferralRedirectPage,
  SuccessPaymentPage,
  TermsOfUse,
  VideoPage,
  LibraryPage,
  PlaylistVideoList,
} from '@/pages';

function App() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const { openModal } = useActions();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [params] = useSearchParams();
  const location = useLocation();
  useEffect(() => {
    if (!isAuth && params.has('popup') && !location.pathname.includes('embed')) {
      openModal({ active: params.get('popup') as ModalType });
    }
  }, [isAuth, params, location.pathname]);

  const layout = () => {
    if (location.pathname.startsWith('/embed')) {
      return <EmbedLayout />;
    }

    if (location.pathname.includes('search') || !isAuth) {
      return <Outlet />;
    }

    return <MainLayout />;
  };

  return (
    <DynamicThemeProvider>
      <ScrollToTop />
      {/* {(onBoarding === null || onBoarding === 'watch') && !isIframe && isAuth ? (
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<FullScreenLoader />}>
                <OnboardingLazy />
              </Suspense>
            }
          />
        </Routes>
      ) : ( */}
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path="/" element={layout()} errorElement={<InDev />}>
            <Route
              index
              element={
                <RequireAuth>
                  <LibraryPage />
                </RequireAuth>
              }
            />

            <Route path="/playlists/:playlistId" element={<LibraryPage />} />
            <Route path="/playlists/:playlistId/videos/:id" element={<VideoPage />} />
            <Route path="/videos/:id" element={<VideoPage />} />

            {/* iframe routes */}
            <Route path="/embed" element={<EmbedLayout />}>
              <Route path="playlist/:playlistId">
                <Route index element={<PlaylistVideoList />} />
                <Route path="videos/:id" element={<VideoPage />} />
              </Route>
              <Route path="videos/:id" element={<VideoPage />} />
            </Route>

            <Route path="landing" element={<LendingLazy />} />
            <Route path="referral/:code/" element={<ReferralRedirectPage />} />
            <Route path="referral-program" element={<ReferalProgramPage />} />
            <Route path="balance" element={<BalancePage />} />
            <Route path="billing-success" element={<SuccessPaymentPage />} />
            <Route path="password/reset/:uid/:token" element={<Password />} />
            <Route path="policy" element={<Policy />}>
              <Route path="personal" element={<PersonalDataPolicy />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="terms_of_use" element={<TermsOfUse />} />
            </Route>
            <Route path="activate/:uid/:token" element={<ActivateLazy />} />
            <Route path="auth/yandex/callback" element={<RedirectRegisterSocialPage />} />
            <Route path="auth/vk/callback" element={<RedirectRegisterSocialPage />} />

            <Route
              path="profile"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route index element={<Profile />} />
            </Route>

            <Route path="/*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
      {/* Modal */}
      <Suspense>
        <Login />
        <Registration />
        <CreatePlaylist />
        <AddMobile />
        <AddVideo />
        <ResetPassword />
        <ResendEmail />
        <RemoveVideo />
        <Notification />
        <VerifyInfo />
      </Suspense>
    </DynamicThemeProvider>
  );
}

export default App;
