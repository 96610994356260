import styled from 'styled-components';

export const VideoCardItemWrapper = styled.div`
  background-color: ${({ theme }) => (theme.isIframe && theme.iframeTheme ? 'transparent' : '#1a1145')};
  position: relative;
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148, 54, 89, 0.3)'
        : 'rgba(168, 85, 247, 0.1)'};
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    border: 1px solid
      ${({ theme: { isIframe, iframeTheme } }) =>
        isIframe && iframeTheme.current === 'light'
          ? 'rgba(168, 85, 247, 0.3)'
          : isIframe && iframeTheme.current === 'cintera'
          ? 'rgb(148 54 89)'
          : 'rgba(168, 85, 247, 0.3)'};
  }
`;

export const VideoPreview = styled.div<{ bgImage: string | null }>`
  aspect-ratio: 16/9;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-image: url(${({ bgImage }) => `${bgImage}`});
  background-color: #251a56;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d8b4fe;
`;

export const CardContent = styled.div`
  padding: 16px;
  min-height: 100px;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: start;
  flex: 1;
`;

export const TitleCard = styled.h3`
  color: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'light' ? '#1A1145' : 'white')};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  word-break: break-word;
  height: 100%;
`;
export const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 29%;
  transform: translate(-50%, -50%);
`;
