import styled, { css } from 'styled-components';

export const MainButton = styled.button<{ isCollapsed: boolean; isActive?: boolean }>`
  text-align: left;
  color: ${({ isActive }) => (isActive ? '#D8B4FE' : 'rgba(255, 255, 255, 0.8)')};
  background-color: ${({ isActive }) => (isActive ? 'rgba(168, 85, 247, 0.2)' : 'transparent')};
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '' : 'rgba(255, 255, 255, 0.05)')};
    color: ${({ isActive }) => (isActive ? '#D8B4FE' : 'white')};
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          padding: 0.5rem;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : css`
          padding: 16px;
          width: 100%;
        `}
`;

export const ButtonContent = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      justify-content: center;
    `}
`;

export const AllVideoText = styled.span`
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
