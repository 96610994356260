import { AllVideoList } from './AllVideoList';
import { Library, LibraryRightSide } from './LibraryPage.styled';
import NavBar from './NavBar/NavBar';
import { PlaylistVideoList } from './PlaylistVideoList';

import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { FullScreenLoader } from '@/components';
import { useAppSelector, useHandlingError, useMatchMedia } from '@/hooks';

export const LibraryPage = () => {
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const { catchError } = useHandlingError();
  const isTablet = useMatchMedia('(max-width: 767px)');
  const [isCollapsed, setIsCollapsed] = useState(isTablet);
  const { playlistId } = useParams();

  const {
    data: playlists,
    isLoading,
    isSuccess,
    error: getPlaylistsError,
  } = playlistsAPI.useGetMyPlaylistsQuery({}, { skip: !isAuth });

  useEffect(() => {
    catchError(getPlaylistsError);
  }, [getPlaylistsError]);

  if (isLoading) return <FullScreenLoader />;
  if (!isSuccess) return null;

  return (
    <Library>
      <NavBar playlistsList={playlists.results} isCollapsed={isCollapsed} onCollapsedChange={setIsCollapsed} />
      <LibraryRightSide>
        {playlistId ? (
          <PlaylistVideoList playlistsList={playlists.results} />
        ) : (
          <AllVideoList playlistsList={playlists.results} />
        )}
      </LibraryRightSide>
    </Library>
  );
};

export default LibraryPage;
