import { AllVideosButton, PlaylistsList, SidebarBottomButtons } from './components';
import { ContentContainer, StyledAside } from './NavBar.styled';

import { useNavigate, useParams } from 'react-router-dom';

import { EditPlaylist, RemovePlaylist } from '@/components';
import { Playlist } from '@/types';

export type SidebarProps = {
  isCollapsed: boolean;
  onCollapsedChange: (isCollapsed: boolean) => void;
  playlistsList: Playlist[];
};

const NavBar = ({ isCollapsed, onCollapsedChange, playlistsList }: SidebarProps) => {
  const navigate = useNavigate();
  const { playlistId } = useParams();

  const handlePlaylistSelect = (id?: string) => {
    navigate(id ? `/playlists/${id}` : '/');
  };

  return (
    <StyledAside isCollapsed={isCollapsed}>
      <ContentContainer isCollapsed={isCollapsed}>
        <AllVideosButton handlePlaylistSelect={handlePlaylistSelect} isCollapsed={isCollapsed} />

        <PlaylistsList
          playlistsList={playlistsList}
          isCollapsed={isCollapsed}
          handlePlaylistSelect={handlePlaylistSelect}
        />

        <SidebarBottomButtons onCollapsedChange={onCollapsedChange} isCollapsed={isCollapsed} />
        {playlistId && (
          <>
            <EditPlaylist id={playlistId} />
            <RemovePlaylist setSelectedPlaylist={handlePlaylistSelect} publicId={playlistId} />
          </>
        )}
      </ContentContainer>
    </StyledAside>
  );
};

export default NavBar;
