//TODO убрать такой импорт
import { TextBlockScroll } from '../../VideoPage/ui/Materials/TranscriptTab/TranscriptVideo.styled';
import { ContentCard } from '../BalancePage.styled';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 35px 8px 20px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 23px;
`;

export const NotResultsText = styled.div`
  display: flex;
  justify-content: center;
  color: rgba(216, 180, 254, 0.5);
  font-size: 16px;
  margin-top: 5%;
`;

export const ReferralHistoryWrapper = styled(ContentCard)`
  max-height: 500px;
  display: flex;
  flex-direction: column;
`;

export const ReferralCard = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.modal.purple_800};
  border-radius: 12px;
  padding: 15px;
  border: 1px solid ${theme.colors.modal.purple_300};

  :hover {
    border-color: ${theme.colors.modal.purple_500};
  }
`;
export const InvitationFriends = styled(motion.div)`
  align-self: center;
`;
export const ScrollWrapper = styled(TextBlockScroll)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 6px; /* ширина scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: rgb(59 42 126); /* цвет плашки */
  }
`;
