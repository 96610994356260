import { AvatarMenuArrow, AvatarWrapper } from './AvatarWithMenu.styled';

import Avatar from '../../Shared/Avatar';
import DropDown from '../../Shared/DropDown/DropDown';
import DropDownMenuItem from '../../Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import ArrowSmall from '../../SvgIcons/ArrowSmall';
import ExitIcon from '../../SvgIcons/ExitIcon';
import LibraryIcon from '../../SvgIcons/LibraryIcon';
import SettingsIcon from '../../SvgIcons/SettingsIcon';
import SignInIcon from '../../SvgIcons/SignInIcon';
import SignUpIcon from '../../SvgIcons/SignUpIcon';

import { memo, Reducer, useMemo, useReducer } from 'react';

import { Gift, RussianRuble } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authAPI, usersAPI } from '@/api';
import { REFRESH_TOKEN } from '@/constants';
import { useActions, useAppDispatch, useAppSearchParams, useAppSelector, useMatchMedia } from '@/hooks';

const AvatarWithMenu = () => {
  const { t, i18n } = useTranslation('header');
  const { setFinishedOnboarding, logout, showNotification } = useActions();
  const [fetchLogout] = authAPI.useLogOutUserMutation();
  const navigate = useNavigate();
  const isMobile = useMatchMedia('(max-width: 480px)');

  const dispatch = useAppDispatch();

  const { setAppParams } = useAppSearchParams();
  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const toggleHandler = () => {
    setOpened(opened);
  };

  const { isAuth: auth, username } = useAppSelector((state) => state.user);

  const { data } = usersAPI.useGetUserMeQuery(undefined, { skip: !auth });

  // const changeLanguage = () => {
  //   switch (i18n.language) {
  //     case 'ru':
  //       i18n.changeLanguage('en');
  //       break;
  //     case 'en':
  //       i18n.changeLanguage('ru');
  //       break;
  //     default:
  //       i18n.changeLanguage('en');
  //   }
  // };

  const exit = async () => {
    try {
      await fetchLogout({ refresh: localStorage.getItem(REFRESH_TOKEN) as string }).unwrap();

      logout();

      navigate('/', { replace: true });

      dispatch(authAPI.util.resetApiState());

      showNotification({
        text: t('logout', { ns: 'success' }),
        severity: 'success',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const items = useMemo(() => {
    if (auth) {
      return [
        {
          text: t('menu.lib'),
          icon: <LibraryIcon />,
          onClick: () => navigate('/'),
        },
        {
          text: t('menu.ref'),
          icon: <Gift />,
          onClick: () => navigate('/referral-program'),
        },
        {
          text: (
            <>
              {isMobile ? (
                <>
                  {t('menu.balance')}{' '}
                  <span
                    style={{
                      backgroundColor: 'rgb(73, 59, 119)',
                      borderRadius: '12px',
                      padding: '2px 8px',
                      marginLeft: '3px',
                    }}
                  >
                    {data?.balance} ₽
                  </span>
                </>
              ) : (
                t('menu.balance')
              )}
            </>
          ),
          icon: <RussianRuble />,
          onClick: () => navigate('/balance'),
        },
        {
          text: t('menu.set'),
          icon: <SettingsIcon />,
          onClick: () => navigate('/profile'),
        },
        // {
        //   text: t('menu.lang'),
        //   icon: <LanguageIcon />,
        //   onClick: changeLanguage,
        // },
        // {
        //   text: t('menu.about'),
        //   icon: <InfoIcon />,
        //   onClick: setFinishedOnboarding,
        // },
        {
          text: t('menu.logOut'),
          icon: <ExitIcon />,
          onClick: exit,
        },
      ];
    }

    return [
      {
        text: t('menu.in'),
        icon: <SignInIcon />,
        onClick: () => setAppParams('popup', 'login'),
      },
      // {
      //   text: t('menu.lang'),
      //   icon: <LanguageIcon />,
      //   onClick: changeLanguage,
      // },
      {
        text: t('menu.up'),
        icon: <SignUpIcon />,
        onClick: () => setAppParams('popup', 'registration'),
      },
    ];
  }, [auth, i18n, navigate, setAppParams, setFinishedOnboarding, t, isMobile]);

  return (
    <DropDown
      isOpen={opened}
      toggleOpen={toggleHandler}
      items={items.map((item, index) => (
        <DropDownMenuItem key={index} icon={item.icon} text={item.text} onClick={item.onClick} />
      ))}
    >
      <AvatarWrapper open={opened}>
        <Avatar auth={auth} size="s" url={data?.avatar} name={username} />
        <AvatarMenuArrow>
          <ArrowSmall className="arrow" />
        </AvatarMenuArrow>
      </AvatarWrapper>
    </DropDown>
  );
};

export default memo(AvatarWithMenu);
