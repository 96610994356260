import { ActionButtons } from './ActionButtonsPlaylist.styled';

import { Edit, Trash } from 'lucide-react';

import { useActions } from '@/hooks';
import { Playlist } from '@/types';

export const ActionButtonsPlaylist = ({
  handlePlaylistSelect,
  playlist,
}: {
  handlePlaylistSelect: (id?: string) => void;
  playlist: Playlist;
}) => {
  const { openModal } = useActions();

  const onEdit = () => {
    handlePlaylistSelect(playlist.publicId);
    openModal({ active: 'EditPlayList' });
  };
  const onRemove = () => {
    handlePlaylistSelect(playlist.publicId);
    openModal({ active: 'RemovePlaylist' });
  };

  return (
    <ActionButtons>
      <button onClick={onEdit}>
        <Edit style={{ alignSelf: 'center' }} size={16} />
      </button>
      <button onClick={onRemove}>
        <Trash style={{ alignSelf: 'center' }} size={16} />
      </button>
    </ActionButtons>
  );
};
