import styled from 'styled-components';

export const Content = styled.div`
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgba(60, 64, 67, 0.07)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : 'rgb(37 26 86)'};
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148, 54, 89, 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 270px;
  max-width: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? '97%' : 'auto')};
  cursor: pointer;

  :hover {
    border: 1px solid
      ${({ theme: { isIframe, iframeTheme } }) =>
        isIframe && iframeTheme.current === 'light'
          ? 'rgba(168, 85, 247, 0.3)'
          : isIframe && iframeTheme.current === 'cintera'
          ? 'rgb(148 54 89)'
          : 'rgba(168, 85, 247, 0.3)'};
  }
`;

export const FragmentTimeV2 = styled.span`
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(26, 17, 69)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : '#e9d5ff'};
  font-size: 14px;
  font-weight: 500;
`;
export const Time = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ShareIconWrapper = styled.div`
  display: none;
  background-color: transparent;

  :hover {
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(168 85 247)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89)'
        : 'rgba(168, 85, 247, 0.4)'};
  }
`;

export const TitleFragment = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-clamp: 2;
  hyphens: none;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие */
  white-space: break-spaces; //Запрещаем перенос строк
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(26, 17, 69)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : '#d8b4fe'};
  line-height: 1.1rem;
  display: block;
`;

export const FrameImage = styled.img<{ isVideoPage?: boolean }>`
  width: 100%;
  border-radius: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: auto;
`;
