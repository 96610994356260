import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  position: relative;
`;

export const IframeWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: flex-end;
`;
