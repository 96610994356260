import styled, { css } from 'styled-components';

export const StyledAside = styled.aside<{ isCollapsed: boolean }>`
  background: #0f0a2b;
  border-right: 1px solid rgba(168, 85, 247, 0.3);
  transition: all 300ms ease-in-out;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          width: 60px;

          @media screen and (max-width: 768px) {
          }
        `
      : css`
          width: 18rem;

          @media screen and (max-width: 768px) {
          }
        `}
`;

export const ContentContainer = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      align-items: center;
      padding: 16px 0;
    `}
`;
