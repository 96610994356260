import { TextBlockScroll } from '../../VideoPage/ui/Materials/TranscriptTab/TranscriptVideo.styled';
import { ContentCard, ContentTitle } from '../BalancePage.styled';
import { TitleDesc } from '../TariffsTab/TarifItem/TarifItem.styled';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const Container = styled(ContentCard)`
  position: relative;
  margin-top: 24px;
`;

export const TitleHistory = styled(ContentTitle)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
`;
export const ScrollBlockHistory = styled(TextBlockScroll)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 15px;
  max-height: 500px;

  ::-webkit-scrollbar {
    width: 6px; /* ширина scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: rgb(59 42 126); /* цвет плашки */
  }
`;

export const ContentCardBlockHistory = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${theme.colors.modal.purple_800};
  border-radius: 12px;
  padding: 15px;
  border: 1px solid ${theme.colors.modal.purple_300};

  :hover {
    border-color: ${theme.colors.modal.purple_500};
  }
`;

export const CountTitle = styled(ContentTitle)`
  font-size: 20px;
  font-weight: 600;
  align-self: flex-end;
  margin-bottom: 0px;
`;

export const CountTitleHistory = styled(CountTitle)`
  color: rgb(74 222 128);
`;

export const CountTitleHistoryRed = styled(CountTitle)`
  color: rgb(248 113 113);
`;

export const TitleDescGreen = styled(TitleDesc)`
  color: rgb(74 222 128);
`;
export const TitleDescRed = styled(TitleDesc)`
  color: rgb(248 113 113);
`;
