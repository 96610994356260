import { AllVideoText, ButtonContent, MainButton } from './AllVideosButton.styled';

import { LayoutList } from 'lucide-react';
import { NavLink } from 'react-router-dom';

export const AllVideosButton = ({
  isCollapsed,
  handlePlaylistSelect,
}: {
  isCollapsed: boolean;
  handlePlaylistSelect: (id?: string) => void;
}) => {
  return (
    <NavLink to="/" end onClick={() => handlePlaylistSelect()}>
      {({ isActive }) => (
        <MainButton isActive={isActive} isCollapsed={isCollapsed}>
          <ButtonContent isCollapsed={isCollapsed}>
            <LayoutList size={20} />
            {!isCollapsed && <AllVideoText>Все видео</AllVideoText>}
          </ButtonContent>
        </MainButton>
      )}
    </NavLink>
  );
};
