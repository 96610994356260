import { Block, Content, FragmentTimeV2, FrameImage, Time, TitleFragment } from './VideoFragmentCard.styled';

import { useLocation } from 'react-router-dom';

import { Clock } from '@/components/SvgIcons/Clock';
import { useIframe } from '@/hooks';
import { Cue } from '@/types';
import { getImageUrl, secondsToTime } from '@/utils';

export interface VideoFragmentCardV2Props {
  fragment: Cue;
  goToTime?: (time: number) => void;
  videoPreview?: string | null;
}

export const VideoFragmentCard = ({
  fragment: { content, startsFrom, image },
  goToTime,
  videoPreview,
}: VideoFragmentCardV2Props) => {
  const location = useLocation();
  const isVideoPage = location.pathname.includes('videos');
  const { isIframe, iframeTheme } = useIframe();

  const goToHandler = () => {
    goToTime?.(startsFrom);
  };

  const getBackgroundImage = () => {
    if (image) {
      return getImageUrl(image);
    }
    return videoPreview ? getImageUrl(videoPreview) : '/images/playlist_mock.png';
  };

  return (
    <Content onClick={goToHandler}>
      <Block>
        <Time>
          <Clock color={isIframe && iframeTheme === 'light' ? 'rgb(26, 17, 69)' : '#E4E4FF'} />
          <FragmentTimeV2>{secondsToTime(startsFrom)}</FragmentTimeV2>
        </Time>
      </Block>
      <div>
        <FrameImage isVideoPage={isVideoPage} src={getBackgroundImage() ?? ''} />
        <TitleFragment dangerouslySetInnerHTML={{ __html: `${content}` }} />
      </div>
    </Content>
  );
};
