import { normalize } from './normalize';
import { theme } from './theme';

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    body {
        font-family: ${theme.fonts.geist}, sans-serif;
        color: ${theme.colors.text.white_100};
        background-color: ${({ theme: { isIframe, iframeTheme } }) =>
          isIframe && iframeTheme.current === 'light'
            ? '#ffffff'
            : iframeTheme.current === 'cintera'
            ? '#1e1f21'
            : 'rgb(15 10 43)'};

        -webkit-font-smoothing: antialiased;
        background-size: cover;
        hyphens: auto;
        position: relative;

        div[id="root"] {
            background-color: ${({ theme: { isIframe, iframeTheme } }) =>
              isIframe && iframeTheme.current === 'light'
                ? '#ffffff'
                : iframeTheme.current === 'cintera'
                ? '#1e1f21'
                : '#0F0A2B'};
            margin: ${({ theme: { isIframe, iframeTheme } }) =>
              isIframe && iframeTheme.current === 'cintera' ? '0' : '15px'};
            padding-bottom: ${({ theme: { isIframe } }) => isIframe && '50px'};
        }

        width: 100%;

        textarea::-webkit-scrollbar {
            width: 5px !important;
        }

        textarea::-webkit-scrollbar-track {
            background-color: #251A56 !important;
            margin: 10px 0 !important;
            border-radius: 20px !important;
        }

        textarea::-webkit-scrollbar-thumb {
            border-radius: 20px !important;
            background-color: rgb(98, 84, 139) !important;
        }

        textarea::-webkit-scrollbar-thumb:hover {
            background-color: rgb(128, 110, 172) !important;
        }

        ::-webkit-scrollbar {
            display: ${({ theme: { isIframe } }) => (isIframe ? 'none' : '')};
            width: 10px; /* ширина scrollbar */
        }

        ::-webkit-scrollbar-track {
            background: transparent; /* цвет дорожки */
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 20px; /* закругления плашки */
            background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
        }
    }

    /* normalize */
    ${normalize}
`;
