import { getStylesSelect } from './consts';

import { ChangeEvent, DragEventHandler, useRef, useState } from 'react';

// eslint-disable-next-line import/named
import axios, { AxiosProgressEvent } from 'axios';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  playlistsAPI,
  useCreateVideoMutation,
  useGetServicesQuery,
  useLazyGetDurationVideoQuery,
  useLazyGetLinkUploadVideoFileQuery,
} from '@/api';
import { useActions } from '@/hooks/useActions';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { useAppSelector } from '@/hooks/useStore';
import { linkRegex } from '@/utils';
import { removeQueryParams } from '@/utils/deleteSearchParamFromURL';

export const useAddVideo = () => {
  const { t } = useTranslation('modal');
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const successPaymentPage = params.get('duration');
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [videoLinkValue, setVideoLinkValue] = useState<string>(params.get('title') ?? '');
  const [selectedPlaylist, setSelectedPlaylist] = useState(params.get('playlist') ?? '');

  const [currentStep, setCurrentStep] = useState<'input' | 'uploading' | 'select-tariff'>(
    successPaymentPage ? 'select-tariff' : 'input',
  );
  const [isLinkError, setIsLinkError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [incorrectVideoFormat, setIncorrectVideoFormat] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [validLink, setValidLink] = useState<string | null>(params.get('link') ?? null);

  const [showInfo, setShowInfo] = useState(false);
  const [duration, setDuration] = useState(Number(params.get('duration')) ?? 0);
  const [progress, setProgress] = useState<number>(0);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const abortUploadRef = useRef<AbortController | null>(null);

  const isCommercial = useAppSelector((state) => state.user.isCommercial);
  const { showNotification, closeModal } = useActions();
  const isTablet = useMatchMedia('(max-width: 768px)');

  const { data: playlists } = playlistsAPI.useGetMyPlaylistsQuery({}, { skip: !isAuth });
  const [addVideoToPlaylist] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [createMovie, result] = useCreateVideoMutation();

  const [getDuration] = useLazyGetDurationVideoQuery();

  const { data: services = [] } = useGetServicesQuery(undefined, { skip: !isAuth });

  const [getLinkUploadVideoFile] = useLazyGetLinkUploadVideoFileQuery();

  const selectedPlaylistDetails = playlists?.results.find((playlist) => playlist.publicId === selectedPlaylist);

  const allowedFormats: { [key: string]: string } = {
    mp4: '.mp4',
    avi: '.avi',
    webm: '.webm',
    mkv: '.mkv',
  };

  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const simulateUpload = () => {
    setProgress(0);
    setCurrentStep('uploading');

    const animate = (time: number) => {
      if (previousTimeRef?.current != undefined) {
        const deltaTime = time - previousTimeRef?.current;
        setProgress((prevCount) => {
          if (Math.round(prevCount) < 100) {
            return (prevCount + deltaTime * 0.01) % 100;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cancelAnimationFrame(requestRef.current);
          return duration ? 100 : 98;
        });
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
  };

  const closeHandler = () => {
    currentStep === 'uploading' && abortUploadRef.current?.abort();
    setCurrentStep('input');
    setVideoLinkValue('');
    setSelectedPlaylist('');
    setIsLinkError(false);
    setIsError(false);
    setIncorrectVideoFormat(false);
    setSelectedFile(null);
    setProgress(0);
    closeModal();
  };

  const uploadFile = async ({
    presignedUrl,
    selectedFile,
    userId,
    validLink,
  }: {
    presignedUrl: string;
    selectedFile: File;
    userId: string;
    validLink: string;
  }) => {
    try {
      setProgress(0);

      const controller = new AbortController();

      abortUploadRef!.current = controller;

      await axios
        .put(presignedUrl, selectedFile, {
          headers: {
            'x-amz-meta-userId': userId,
          },
          signal: controller.signal,

          onUploadProgress: (event: AxiosProgressEvent) => {
            if (event.total) {
              const percentCompleted = Math.round((event.loaded * 100) / event.total);

              if (percentCompleted < 92) {
                setProgress(percentCompleted);
              }

              if (percentCompleted === 100) {
                const curfileInputRef = fileInputRef.current;
                if (curfileInputRef) {
                  curfileInputRef.value = '';
                }
              }
            }
          },
        })
        .then(async () => {
          //получаем длительность видеофайла
          const data = await getDuration({ url: validLink }).unwrap();
          setDuration(data);
          setProgress(100);
          setCurrentStep('select-tariff');
        })
        .catch(() => {
          setProgress(0);
          setCurrentStep('input');
          showNotification({
            text: 'Загрузка файла была отменена',
            severity: 'error',
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload canceled:', error.message);
      } else {
        setCurrentStep('input');
        console.error('Upload failed:', error);
      }
      setProgress(0);
      setIsError(true);
    }
  };

  const handleVideoLink = async () => {
    simulateUpload();
    const data = await getDuration({ url: videoLinkValue }).unwrap();

    setProgress(100);
    setDuration(data);
    flushSync(() => {
      setCurrentStep('select-tariff');
    });
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const fileType = selectedFile.type.replace('video/', '');
      const format = allowedFormats[fileType] || '';
      const fileName = selectedFile.name.replace(/\.(mp4|avi|mkv|webm)$/, '');

      const formData = new FormData();
      formData.append('file', selectedFile);

      const linkData = await getLinkUploadVideoFile({ file_name: fileName, file_type: format }).unwrap();
      const validLink = removeQueryParams(linkData.presignedUrl);
      setValidLink(validLink);

      await uploadFile({
        presignedUrl: linkData.presignedUrl,
        selectedFile,
        userId: linkData['x-amz-meta-userId'],
        validLink,
      });
    }
  };

  const handleError = () => {
    setIsError(true);
    showNotification({
      text: 'К сожалению, видео по ссылке недоступно. Проверьте настройки приватности видео и повторите попытку',
      severity: 'error',
    });
    setCurrentStep('input');
    closeHandler();
  };

  const addVideo = async () => {
    try {
      setProgress(0);
      setCurrentStep('uploading');

      if (videoLinkValue.length) {
        await handleVideoLink();
      }
      if (selectedFile && videoLinkValue === '') {
        await handleFileUpload();
      }
    } catch (error) {
      handleError();
    }
  };

  const saveVideo = async (servicePublicId: string) => {
    try {
      const response = await createMovie({
        originLink: successPaymentPage ? params.get('link') || '' : videoLinkValue || validLink || '',
        servicePublicId,
      }).unwrap();

      if (selectedPlaylist) {
        await addVideoToPlaylist({
          playlistId: selectedPlaylist,
          videos: [
            {
              videoPublicId: response.publicId,
              isAiSuggested: false,
            },
          ],
        }).unwrap();
      }

      showNotification({
        text: t('add_video', { ns: 'success' }),
        severity: 'success',
        link: `/videos/${response.publicId}?t=0`,
      });

      closeHandler();
    } catch (e) {
      showNotification({
        text: t('error', { ns: 'error' }),
        severity: 'error',
      });
      closeHandler();
      navigate('/');
    }
  };

  const videoLinkHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setVideoLinkValue(trimmedValue);
    const isValidResourceLink = linkRegex.test(trimmedValue);

    if (isValidResourceLink) {
      setIsLinkError(false);
    } else {
      setIsLinkError(true);
    }
    if (!trimmedValue && !isValidResourceLink) {
      setIsError(false);
      setIsLinkError(false);
    }
  };

  const handleFilePrompt = () => {
    if (videoLinkValue === '') {
      fileInputRef.current?.click();
    }
  };

  const validateFile = (file: File) => {
    const maxFileSize = 5 * 1024 * 1024 * 1024;
    console.log(file.type, duration);
    // '' потому что при загрузке mkv не всегда определяется тип файла.
    // Таким образом убираем отображение ошибки при валидном файле mkv, при этом проверка на размер файла остается
    if (
      file.size <= maxFileSize &&
      ['video/mp4', 'video/avi', 'video/mkv', 'video/x-matroska', 'application/x-matroska', '', 'video/webm'].includes(
        file.type,
      )
    ) {
      setIncorrectVideoFormat(false);
      setSelectedFile(file);
    } else {
      setIncorrectVideoFormat(true);
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const file = event.dataTransfer?.files[0];
    if (file) {
      validateFile(file);
    }
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      validateFile(file);
      e.target.value = '';
      setSelectedFile(file);
    }
  };

  const deleteVideo = () => {
    setSelectedFile(null);
  };

  const options =
    playlists?.results.map((playlist) => ({
      value: playlist.publicId,
      label: playlist.title,
      isSelectedOption: selectedPlaylist === playlist.publicId,
    })) || [];

  const stylesSelect = getStylesSelect(options);

  // const handleCancelUpload = () => {
  //   if (abortController) {
  //     abortController.abort();
  //     setIsSuccess(false);
  //     setIsAborted(true);
  //     setSelectedFile(null);
  //     setPresignedUrl('');
  //     setProgress(0);
  //   }
  // };

  return {
    videoLinkValue,
    selectedPlaylist,
    isLinkError,
    isError,
    selectedFile,
    videoLinkHandler,
    addVideo,
    closeHandler,
    setSelectedPlaylist,
    setSelectedFile,
    deleteVideo,
    handleDrop,
    handleDragOver,
    onFileChange,
    handleFilePrompt,
    playlists,
    t,
    setVideoLinkValue,
    fileInputRef,
    incorrectVideoFormat,
    isTablet,
    result,
    stylesSelect,
    options,
    isCommercial,
    setIsError,
    setShowInfo,
    showInfo,
    services,
    currentStep,
    setCurrentStep,
    duration,
    selectedPlaylistDetails,
    progress,
    saveVideo,
    validLink,
  };
};
