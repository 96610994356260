import { Container, NotVideoText, VideoGrid } from './AllVideoList.styled';

import { useVideos } from '../../model';
import { AddButton } from '../NavBar/components';

import { useEffect } from 'react';

import Pagination from '@mui/material/Pagination';

import { useGetMyVideosQuery, videosAPI } from '@/api';
import { FullScreenLoader, RemoveVideo, VideoCard } from '@/components';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import { usePagination } from '@/hooks';
import { PaginationWrapper } from '@/styles';
import { Playlist } from '@/types';

type AllVideoListProps = {
  playlistsList: Playlist[];
};

export const AllVideoList = ({ playlistsList }: AllVideoListProps) => {
  const { isAuth, makeSearch, isIframe, openModal, catchError, searchQueryValue } = useVideos();
  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const {
    data: videosResponse,
    isLoading: isGetVideosLoading,
    error,
    isSuccess: videoIsSuccess,
  } = useGetMyVideosQuery({ params: { page } }, { skip: !isAuth });
  const videos = videosResponse?.results || [];

  const { data: searchVideos, isLoading: isSearchVideosLoading } = videosAPI.useGetVideosQuery(
    {
      params: {
        title: searchQueryValue || '',
        pageSize: 100,
      },
    },
    { skip: !searchQueryValue || !isAuth },
  );

  useEffect(() => {
    if (videoIsSuccess) {
      setCount(videosResponse.results.length === 0 ? 1 : videosResponse.count);
    } else {
      catchError(error);
    }
  }, [videosResponse, error]);

  const renderSearchResults = () => {
    if (!searchQueryValue) {
      return videos?.map((video, i) => (
        <VideoCard
          isIframe={isIframe}
          key={i}
          playlistsList={playlistsList}
          movie={video}
          to={`${isIframe ? 'embed' : ''}/videos/${video.publicId}?t=${video.startsFrom ?? 0}`}
        />
      ));
    }

    return searchVideos?.results.map((video) => (
      <VideoCard
        isIframe={isIframe}
        to={`${isIframe ? 'embed' : ''}/videos/${video.publicId}?t=${video.startsFrom ?? 0}`}
        playlistsList={playlistsList}
        key={video.publicId}
        movie={video}
      />
    ));
  };

  if (!videos?.length && !isGetVideosLoading) {
    return (
      <NotVideoText>
        <AddButton
          onClick={() => openModal({ active: 'AddVideo' })}
          style={{ width: '250px', height: '40px' }}
          isCollapsed={false}
        >
          <span style={{ fontSize: '16px', color: 'white' }}>Добавить видео</span>
        </AddButton>
      </NotVideoText>
    );
  }

  return (
    <Container>
      {isGetVideosLoading && <FullScreenLoader />}
      <div style={{ marginBottom: '16px' }}>
        <SearchInVideoInput placeholder="Какое видео ищем в Visaver?" getSearch={makeSearch} />
      </div>
      {searchVideos && searchVideos.results.length === 0 && searchQueryValue && !isSearchVideosLoading && (
        <NotVideoText>Результатов по запросу не найдено</NotVideoText>
      )}
      <VideoGrid>{renderSearchResults()}</VideoGrid>

      {!isGetVideosLoading && pages > 1 && (
        <PaginationWrapper>
          <Pagination
            count={pages}
            page={page}
            color="primary"
            onChange={(e, newPage) => handleChangePage(e, newPage)}
          />
        </PaginationWrapper>
      )}
      <RemoveVideo />
    </Container>
  );
};
