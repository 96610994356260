import { Tariffs } from './Tariffs';
import { AnimatedComponent } from './Uploading';

import Done from '@mui/icons-material/Done';
import Select from 'react-select';

import InputModal from '@/components/InputModal/InputModal';
import Button from '@/components/Shared/Buttons/Button/Button';
import { ButtonModalTextStyle, ButtonsWrapper } from '@/components/Shared/Modals/AddVideo/AddVideo.styled';
import {
  AddVideoText,
  AddVideoText1,
  AddVideoWrapper,
  SelectedFileWrapper,
} from '@/components/Shared/Modals/AddVideo/DragOnDrop/DragOnDrop.styled';
import {
  ButtonArrowLeftModal,
  Container,
  ErrorTextWrapper,
  LinkVK,
  LinkYT,
  SecondaryTextErrorFormat,
  SelectPlaylistWrapper,
  TextAddVideo,
  TextError,
  TitleAddVideo,
  VideoFileErrorBlock,
} from '@/components/Shared/Modals/AddVideo/SelectVideo/SelectVideo.styled';
import { Inner, Modal } from '@/components/Shared/Modals/Modal';
import { AddVideoIcon } from '@/components/SvgIcons/AddVideoIcon';
import ArrowLeftModal from '@/components/SvgIcons/ArrowLeftModal';
import clearInputIcon from '@/components/SvgIcons/clearInputIcon.svg';
import { LabelTextPurple } from '@/components/UploadProgress/UploadProgressAddVideoFile.styled';
import { useAddVideo } from '@/hooks/useAddVideo';
import { TextCenter } from '@/styles';

export function AddVideo() {
  const {
    videoLinkValue,
    isLinkError,
    isError,
    selectedFile,
    videoLinkHandler,
    addVideo,
    closeHandler,
    setSelectedPlaylist,
    handleDrop,
    handleDragOver,
    onFileChange,
    t,
    setVideoLinkValue,
    fileInputRef,
    incorrectVideoFormat,
    stylesSelect,
    options,
    setShowInfo,
    services,
    currentStep,
    setCurrentStep,
    progress,
    duration,
    saveVideo,
    selectedPlaylist,
    validLink,
  } = useAddVideo();

  const noPlaylistOption = [
    {
      value: 'no_playlists',
      label: 'У вас пока нет плейлистов',
      isDisabled: true,
    },
  ];

  const formatOptionLabel = () => (data: any) =>
    (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span>{data.label}</span>
        {data.isSelectedOption && <Done />}
      </div>
    );

  const renderStepContent = () => {
    switch (currentStep) {
      case 'input':
        return (
          <div>
            <Container style={{ position: 'relative' }}>
              <TextAddVideo>{t('text_link', { ns: 'modal' })}</TextAddVideo>
              <InputModal
                style={{
                  borderColor:
                    (isError && videoLinkValue !== '') || (isLinkError && videoLinkValue !== '') ? '#EF7A80' : '',
                  paddingRight: '38px',
                }}
                placeholder={t('video_insert')}
                value={videoLinkValue}
                onChange={videoLinkHandler}
                disabled={selectedFile !== null}
              />
              {videoLinkValue !== '' && (
                <button onClick={() => setVideoLinkValue('')}>
                  <img
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: `${!isError && !isLinkError ? '58%' : '41%'}`,
                      right: '15px',
                    }}
                    src={clearInputIcon}
                    alt="clearInputIcon"
                  />
                </button>
              )}
              {isError && videoLinkValue !== '' ? (
                <ErrorTextWrapper>
                  <TextError>{t('incorrect_video_link', { ns: 'error' })}</TextError>
                  <TextError>{t('incorrect_video_link1', { ns: 'error' })}</TextError>
                </ErrorTextWrapper>
              ) : null}

              {isLinkError && videoLinkValue !== '' ? (
                <ErrorTextWrapper>
                  <TextError>{t('incorrect_video_link_tiktok', { ns: 'error' })}</TextError>
                  <TextError>
                    {t('incorrect_video_link_tiktok1', { ns: 'error' })}
                    <LinkVK to={'https://vk.com/video'}>VK, </LinkVK>
                    <LinkYT to={'https://www.youtube.com'}>YouTube, </LinkYT>
                    <LinkYT to={'https://rutube.ru'}>RuTube, </LinkYT>
                    <LinkYT to={'https://kinescope.ru'}>Kinescope</LinkYT>
                  </TextError>
                </ErrorTextWrapper>
              ) : null}
            </Container>
            <TextCenter>{t('or_add_video')}</TextCenter>
            <Container>
              <AddVideoWrapper
                onClick={() => fileInputRef.current?.click()}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                videoLinkValue={videoLinkValue}
              >
                <div style={{ display: 'flex', justifyContent: 'center', opacity: '0.5' }}>
                  <AddVideoIcon />
                </div>
                <input
                  id="file1"
                  type="file"
                  onChange={onFileChange}
                  ref={fileInputRef}
                  hidden
                  accept=".mkv, .mp4, .webm, .avi" //audio/ogg
                />
                <div style={{ position: 'relative', textAlign: 'center' }}>
                  {/*<label htmlFor="file1">*/}
                  <AddVideoText1 videoLinkValue={videoLinkValue} onMouseOver={() => setShowInfo(true)}>
                    {t('add_video_text1')}
                  </AddVideoText1>
                  {/*</label>*/}
                  <AddVideoText onMouseOver={() => setShowInfo(true)}>{t('add_video_text2')}</AddVideoText>
                </div>
                {selectedFile && (
                  <SelectedFileWrapper>
                    <LabelTextPurple style={{ marginBottom: '0px' }}>Выбран: {selectedFile.name}</LabelTextPurple>
                  </SelectedFileWrapper>
                )}
              </AddVideoWrapper>
              {incorrectVideoFormat && (
                <VideoFileErrorBlock>
                  <SecondaryTextErrorFormat>{t('video_format_invalid1')}</SecondaryTextErrorFormat>
                  <SecondaryTextErrorFormat>{t('video_format_invalid2')}</SecondaryTextErrorFormat>
                </VideoFileErrorBlock>
              )}
            </Container>
            <SelectPlaylistWrapper selectedFile={selectedFile} videoLinkValue={videoLinkValue}>
              <Container>
                <Select
                  options={options.length === 0 ? noPlaylistOption : options}
                  isDisabled={!videoLinkValue && !selectedFile}
                  onChange={(selectedOption) => setSelectedPlaylist(selectedOption?.value || '')}
                  placeholder={t('video_no_select')}
                  menuPortalTarget={document.body}
                  formatOptionLabel={formatOptionLabel()}
                  styles={stylesSelect}
                />
              </Container>
            </SelectPlaylistWrapper>
            <div style={{ width: '100%', backgroundColor: 'transparent' }}>
              <ButtonsWrapper onMouseOver={() => setShowInfo(false)}>
                <Button onClick={closeHandler} themed="colored-default" variant="colored-default">
                  <ButtonModalTextStyle>Отмена</ButtonModalTextStyle>
                </Button>
                <Button
                  onClick={addVideo}
                  disabled={(!selectedFile && !videoLinkValue) || isError || isLinkError}
                  themed="colored"
                  variant="colored"
                >
                  <ButtonModalTextStyle>Добавить видео</ButtonModalTextStyle>
                </Button>
              </ButtonsWrapper>
            </div>
          </div>
        );

      case 'uploading':
        return <AnimatedComponent videoName={selectedFile?.name || videoLinkValue} progress={progress} />;

      case 'select-tariff':
        return (
          <Tariffs
            validLink={validLink ?? videoLinkValue}
            selectedPlaylist={selectedPlaylist}
            videoLinkValue={videoLinkValue ?? ''}
            videoName={selectedFile?.name ?? ''}
            duration={duration}
            closeHandler={closeHandler}
            saveVideo={saveVideo}
            services={services}
          />
        );
    }
  };

  return (
    <Modal modal={'AddVideo'} onClose={closeHandler} closeButton>
      <Inner modal={'AddVideo'}>
        <div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {currentStep === 'select-tariff' && (
              <ButtonArrowLeftModal onClick={() => setCurrentStep('input')}>
                <ArrowLeftModal />
              </ButtonArrowLeftModal>
            )}
            <TitleAddVideo>
              {currentStep === 'input' && 'Добавить видео'}
              {currentStep === 'uploading' && 'Загрузка видео'}
              {currentStep === 'select-tariff' && 'Выберите операции'}
            </TitleAddVideo>
          </div>

          {renderStepContent()}
        </div>
      </Inner>
    </Modal>
  );
}
