import { CardContent, CardHeader, PlayButton, TitleCard, VideoCardItemWrapper, VideoPreview } from './VideoCard.styled';

import { VideoDropDownMenu, VideoDropDownWrapper } from '../../Shared/VideoDropdown';
import DotsVerticalIcon from '../../SvgIcons/DotsVerticalIcon';
import PlayIcon from '../../SvgIcons/PlayIcon';

import { memo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { useActions } from '@/hooks';
import { Playlist, Video, VideoInPlaylist } from '@/types';

export type VideoCardPropsType = {
  movie: VideoInPlaylist | Video;
  to?: string;
  playlistsList?: Playlist[];
  isIframe?: boolean;
};
export const VideoCard = memo(({ movie, to, playlistsList, isIframe }: VideoCardPropsType) => {
  const { t } = useTranslation('components');
  const { title, thumbnailUrl } = movie;
  const location = useLocation();
  const [addToPlayList] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [deleteFromPlayList] = playlistsAPI.useRemoveVideoFromPlaylistMutation();
  const { showNotification } = useActions();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const [playlistsForVideo, setPlaylistsForVideo] = useState((movie as VideoInPlaylist).playlists ?? []);

  const handleTogglePlaylist = async (videoId: string, playlistId: string, isSelected: boolean) => {
    try {
      if (isSelected) {
        await deleteFromPlayList({ videoPublicId: videoId, playlistId });
        setPlaylistsForVideo((prev: string[]) => prev.filter((id) => id !== playlistId));
        showNotification({
          text: t('del_v', { ns: 'success' }),
          severity: 'success',
        });
      } else {
        await addToPlayList({
          playlistId: playlistId,
          videos: [{ videoPublicId: videoId, isAiSuggested: false }],
        });
        setPlaylistsForVideo((prev: string[]) => [...prev, playlistId]);
        showNotification({
          text: t('add_videos_pl_n', {
            ns: 'success',
            name: playlistsList?.find((p) => p.publicId === playlistId)?.title ?? '',
          }),
          severity: 'success',
        });
      }
    } catch (e) {
      showNotification({
        text: 'Произошла ошибка, повторите позже',
        severity: 'error',
      });
    }
  };

  return (
    <VideoCardItemWrapper>
      <Link to={to ?? ''} state={`${location.pathname}`}>
        <VideoPreview bgImage={thumbnailUrl}>
          <PlayButton>
            <PlayIcon />
          </PlayButton>
        </VideoPreview>
      </Link>
      <CardContent>
        <CardHeader>
          <Link to={to ?? ''} state={`${location.pathname}`}>
            <TitleCard>{title.slice(0, 100)}</TitleCard>
          </Link>

          {!isIframe && (
            <VideoDropDownWrapper>
              <DotsVerticalIcon onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
              {isDropdownOpen && (
                <VideoDropDownMenu
                  playlistsForVideo={playlistsForVideo}
                  isDropdownOpen={isDropdownOpen}
                  ref={dropdownRef}
                  onClose={() => setIsDropdownOpen(false)}
                  video={movie}
                  playlistsList={playlistsList ?? []}
                  handleTogglePlaylist={handleTogglePlaylist}
                />
              )}
            </VideoDropDownWrapper>
          )}
        </CardHeader>
      </CardContent>
    </VideoCardItemWrapper>
  );
});

VideoCard.displayName = 'VideoCard';
