import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StepContainer = styled(motion.div)`
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #110b30;
  border: 1px solid rgb(98, 84, 139);

  &:hover {
    border: 1px solid rgb(168, 85, 247);
  }
`;

export const Description = styled.div<{ step: number }>`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: ${({ step }) => (step === 1 ? 'pointer' : 'auto')};

  &:hover {
    opacity: ${({ step }) => (step === 1 ? 0.7 : 1)};
  }
`;
