import {
  ContentWrapper,
  Count,
  IconWrapper,
  ReferralInfoBlock,
  ReferralInfoWrapper,
  ReferralLink,
  ReferralLinkWrapper,
  StepsWrapper,
  Text,
  TextRefInfo,
  TextWrapper,
  Title,
  Wrapper,
} from './ReferalProgramPage.styled';
import { ReferralLinkInput } from './ReferralLinkInput';
import { ShareButtons } from './ShareButtons/ShareButtons';
import { Steps } from './Steps';

import { RussianRubleIcon } from 'lucide-react';

import { referralAPI } from '@/api/referralApi';
import { Button } from '@/components';
import GuestIcon from '@/components/SvgIcons/GuestIcon';
import { useActions, useAppSelector } from '@/hooks';

export interface StepItem {
  step: number;
  text: string;
  description?: string;
}

const steps: StepItem[] = [
  {
    step: 1,
    text: 'Отправь другу свою реферальную ссылку',
    description: 'Копировать ссылку',
  },
  {
    step: 2,
    text: 'Получи 100 рублей на счёт',
    description: 'Если друг зарегистрируется и пополнит баланс на сумму от 100 рублей',
  },
  {
    step: 3,
    text: 'Бонус начисляется только за первое пополнение нового аккаунта',
  },
  {
    step: 4,
    text: 'Использовать свою реферальную ссылку можно неограниченное количество раз',
  },
];

export const ReferalProgramPage = () => {
  const { data: referralData, isLoading: isReferralDataLoading } = referralAPI.useGetReferralDataQuery();
  const referallink = useAppSelector((state) => state.user.referralLink);
  const { showNotification } = useActions();

  const referralInfo = [
    { title: 'Твои рефералы', count: referralData && referralData.referralsCount },
    { title: 'Бонусов получено', count: referralData && referralData.referralsBonus },
  ];

  const copyReferralLink = async () => {
    if (referallink) {
      await navigator.clipboard.writeText(referallink);
      showNotification({
        text: 'Реферальная ссылка скопирована!',
        severity: 'success',
      });
    } else {
      showNotification({
        text: 'Произошла ошибка, повторите позже',
        severity: 'error',
      });
    }
  };

  if (isReferralDataLoading) {
    return null;
  }

  return (
    <Wrapper>
      <div>
        <Title initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
          Реферальная программа
        </Title>
        <Text initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
          Приглашай друзей — получай бонусы!
        </Text>
      </div>

      <ContentWrapper initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
        <ReferralInfoWrapper initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
          {referralInfo.map((item, i) => (
            <ReferralInfoBlock key={i}>
              <IconWrapper>
                {i === 0 ? <GuestIcon stroke="#05C193" /> : <RussianRubleIcon color="#05C193" />}
              </IconWrapper>
              <TextWrapper>
                <TextRefInfo>{item.title}</TextRefInfo>
                <Count>
                  {item.count} {i === 1 ? '₽' : ''}
                </Count>
              </TextWrapper>
            </ReferralInfoBlock>
          ))}
        </ReferralInfoWrapper>

        <ReferralLinkWrapper>
          <Text>Твоя персональная реферальная ссылка</Text>
          <ReferralLink>
            <ReferralLinkInput onClick={copyReferralLink} referralLink={referallink} />
            <Button onClick={copyReferralLink} themed="colored" variant="colored">
              Копировать ссылку
            </Button>
          </ReferralLink>
          <Text style={{ color: '#62548B' }}>Получи 100 ₽ за каждого нового друга</Text>
        </ReferralLinkWrapper>
        <ShareButtons referralLink={referallink} />
      </ContentWrapper>

      <StepsWrapper>
        {steps.map((item) => (
          <Steps key={item.step} stepItem={item} onClick={copyReferralLink} />
        ))}
      </StepsWrapper>
    </Wrapper>
  );
};
