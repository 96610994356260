import {
  Container,
  DotItem,
  Price,
  Tarif,
  TarifDetail,
  TarifDetailList,
  TariffsContainerBlock,
  TariffsText,
  TariffsWrapper,
  UnlimitedText,
} from './Tariffs.styled';

import { ButtonModalTextStyle, ButtonsWrapper, FooterAddVideo } from '../AddVideo.styled';
import { MyBalanceBlock } from '../MyBalanceBlock';

import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { usersAPI } from '@/api';
import { Button, ContainerDoneLoading, DoneLoadingWrapper, LabelTextPurple } from '@/components';
import { LabelText, theme } from '@/styles';
import { ServiceResponse } from '@/types';
import { VKLinkRegex, youtubeRegex } from '@/utils';

export const Tariffs = ({
  duration,
  services,
  videoName,
  saveVideo,
  closeHandler,
  videoLinkValue,
  selectedPlaylist,
  validLink,
}: {
  saveVideo: (id: string) => void;
  closeHandler: () => void;
  videoName?: string;
  duration: number;
  services: ServiceResponse[];
  videoLinkValue?: string;
  selectedPlaylist: string;
  validLink: string | null;
}) => {
  const { t } = useTranslation('pages');

  const { data: dataMe } = usersAPI.useGetUserMeQuery();
  const [params] = useSearchParams();
  const titleVideo = params.get('title');
  const [showBalanceStatus, setShowBalanceStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTariff, setSelectedTariff] = useState<ServiceResponse | null>(null);

  /** @param {number} Стоимость обработки видео */
  const [estimatedCost, setEstimatedCost] = useState(0);

  /** @param {boolean} true - можем обновлять видео */
  const [canProcessVideo, setCanProcessVideo] = useState(false);

  const isTariffWithDisabled = useMemo(() => {
    if (videoLinkValue) {
      const isYouTube = youtubeRegex.test(videoLinkValue);
      const isVK = VKLinkRegex.test(videoLinkValue);

      if (isYouTube || isVK) {
        return false;
      } else {
        return true;
      }
    }
    if (videoName) {
      return true;
    }
  }, [videoLinkValue, videoName]);

  const chooseTariff = (tariff: ServiceResponse) => {
    const price = tariff.data.price;

    setSelectedTariff(tariff);

    if (price !== 0) {
      const estimatedCost = price * duration;

      const balance = dataMe?.balance ?? 0;
      setEstimatedCost(estimatedCost);
      balance >= estimatedCost ? setCanProcessVideo(true) : setCanProcessVideo(false);
      setShowBalanceStatus(true);
    } else {
      setEstimatedCost(0);
      setCanProcessVideo(true);
      setShowBalanceStatus(false);
    }
  };

  return (
    <Container>
      <div>
        <ContainerDoneLoading>
          <LabelTextPurple>Загруженное видео:</LabelTextPurple>
          <DoneLoadingWrapper>
            <LabelText style={{ fontWeight: '500' }}>{titleVideo || videoName || videoLinkValue}</LabelText>
          </DoneLoadingWrapper>
          <LabelTextPurple>Длительность: {duration} мин</LabelTextPurple>
          <LabelTextPurple style={{ fontSize: '14px' }}>
            *Длительность видео округляется до целых минут в большую сторону
          </LabelTextPurple>
        </ContainerDoneLoading>
      </div>
      <TariffsContainerBlock>
        {services &&
          services.map((tariff) => {
            const {
              data: { price, features, title },
              publicId,
            } = tariff;

            const disabled = price === 0 && isTariffWithDisabled;

            return (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label
                aria-disabled={disabled}
                key={publicId}
                style={{ position: 'relative', pointerEvents: disabled ? 'none' : 'auto' }}
              >
                <TariffsWrapper isDisabled={disabled} onClick={() => chooseTariff(tariff)}>
                  <Tarif>
                    <div>
                      <TariffsText>{title}</TariffsText>
                      <Price>
                        {price} ₽/{t('balance.minut')}
                        {price === 0 && <UnlimitedText>Безлимит</UnlimitedText>}
                      </Price>
                    </div>
                    <input
                      disabled={disabled}
                      type="radio"
                      name="tariff"
                      style={{ accentColor: `${theme.colors.modal.purple_500}` }}
                    />
                  </Tarif>
                  <TarifDetailList>
                    {features.map((feature, idx) => (
                      <TarifDetail key={idx}>
                        <DotItem />
                        {feature}
                      </TarifDetail>
                    ))}
                  </TarifDetailList>
                </TariffsWrapper>
              </label>
            );
          })}
      </TariffsContainerBlock>
      <FooterAddVideo>
        {dataMe && (
          <MyBalanceBlock
            validLink={validLink || videoLinkValue || ''}
            videoName={videoLinkValue || videoName || ''}
            duration={duration}
            selectedPlaylist={selectedPlaylist}
            countBalance={dataMe?.balance}
            canProcessVideo={canProcessVideo}
            showBalanceStatus={showBalanceStatus}
            onClose={closeHandler}
            estimatedCost={estimatedCost}
          />
        )}
        <ButtonsWrapper>
          <Button onClick={closeHandler} themed="colored-default" variant="colored-default">
            <ButtonModalTextStyle>Отмена</ButtonModalTextStyle>
          </Button>
          <Button
            onClick={() => {
              setIsLoading(true);
              saveVideo(selectedTariff!.publicId);
            }}
            disabled={!canProcessVideo || !selectedTariff || isLoading}
            themed="colored"
            variant="colored"
          >
            <ButtonModalTextStyle>Продолжить</ButtonModalTextStyle>
          </Button>
        </ButtonsWrapper>
      </FooterAddVideo>
    </Container>
  );
};
