import { Suspense } from 'react';

import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';

import { Main, Wrapper } from '@/App.styled';
import { Container, FullScreenLoader, Header } from '@/components';
import { useAppSelector } from '@/hooks';
import { Footer } from '@/pages/Lending/ui/Footer';

export const MainLayout = () => {
  const activeModal = useAppSelector((state) => state.modal.active);

  return (
    <Wrapper isFixed={Boolean(activeModal)} isMobile={isMobile}>
      <Header />
      <Main>
        <Suspense fallback={<FullScreenLoader />}>
          <Container>
            <Outlet />
          </Container>
        </Suspense>
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default MainLayout;
