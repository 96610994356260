import { ContainerStyled } from './Container.styled';

import { ReactNode } from 'react';

interface ContainerProps {
  children?: ReactNode;
}

export const Container = ({ children, ...props }: ContainerProps) => {
  return <ContainerStyled {...props}>{children}</ContainerStyled>;
};
