export default {
  header: {
    search: 'Search public playlists on ViSaver',
    search_mobile: 'Public playlist name',
    addVideo: 'Add video',
    createPlaylist: 'Create playlist',
    menu: {
      lib: 'My library',
      stat: 'Statistics',
      ref: 'Referral program',
      set: 'Account settings',
      about: 'About',
      admin: 'Editor',
      lang: 'Русскоязычная версия',
      balance: 'Balance',
      logOut: 'Log out',
      in: 'Log in',
      up: 'Registration',
      search: 'Search video',
    },
  },
  pages: {
    quizPage: {
      result_title: 'All questions completed!',
      restart_quiz_btn: 'Retake the quiz',
      go_to_video_btn: 'Return to the video',
      count_quiz: '{{correctCount}} out of {{questionsCount}} answers correct.',
      correct_total_quiz: 'Well done!',
      question_quiz: 'Question {{activeQuestionIndex}} of {{questionsLength}}',
    },
    statistics: {
      limit: 'Playlists on the page:',
      view: 'Views',
      like: 'Likes',
      repost: 'Reposts',
      comment: 'Comments',
      save: 'Saved',
      sort: 'Sort by',
      name: 'Name',
    },
    account: {
      photo: 'Change',
      personal: 'Editor',
      info_text: 'Here you can edit any text content (notes, transcripts, timestamps, quizzes) from your videos.',
    },
    library: {
      files: 'Files',
      no_pl:
        'There are no playlists here yet, but the ‘Create playlist’ button in the page header will help add content',
      no_v: 'There are no videos here yet, but the ‘Add video’ button in the page header will help you add content',
    },
    politic: {
      go_main: 'Go back to the main page',
    },
    main: {
      no_result: 'There are no results for your search',
      no_pl: 'Users have not shared playlists yet',
    },
    onboarding: {
      buttons: {
        nextGo: 'Next',
        go: 'Start now!',
        skip: 'Skip',
      },
      sl1: {
        sl1_text: 'All you need',
        sl1_text2: 'for video-based learning',
      },
      sl2: {
        sl2_h1: 'Add Videos',
        sl2_text1: 'Simply paste a video link or',
        sl2_text2: 'upload a file from your device.*',
        sl2_span: '*For organizations, we accept videos from any source, including hard disks.',
      },
      sl3: {
        sl3_h1: 'Move Between Segments',
        sl3_text1: 'Navigate among parts of the video using',
        sl3_text2: 'explanatory',
        sl3_word: 'timestamps.',
      },
      sl4: {
        sl4_h1: 'See Info Structure',
        sl4_text1: 'Learn by going through well-organized ',
        sl4_word: 'summaries',
      },
      sl5: {
        sl5_h1: 'Check Yourself',
        sl5_text1: 'Take',
        sl5_text2: 'and rewatch moments with correct answers when needed.',
        sl5_word: 'tests',
      },
      sl6: {
        sl6_h1: 'Find Anything',
        sl6_word: 'Search',
        sl6_text1: ' video content like text — within individual videos or entire playlists.',
        sl6_text4: 'within individual videos or entire playlists.',
      },

      sl1_t: 'Your personal video list from favorite sources',
      sl2_save: 'Save',
      sl2_save_d: 'all the videos you like in one place',
      sl2_watch: 'Watch',
      sl2_watch_d: 'the best playlists made by others',
      sl2_share: 'Share',
      sl2_share_d: 'useful playlists with your friends',
      sl3_t: 'The library displays all added videos and your created playlists',
      sl4_t1: 'Create playlists for yourself or share with others and get special bonuses!',
      sl4_t2: 'Like, comment, and share playlists on social networks',
      sl5_tg: 'Telegram:',
      sl5_eml: 'Email:',
      innerSlider: {
        s0_n: 'Everything for UX/UI beginners',
        s0_d: 'Basic knowledge',
        s1_n: 'Film trailers',
        s1_d: 'You can quickly and easily choose what you want to watch',
        s2_n: 'Fast Recipes',
        s2_d: "Extra-fast recipes that'll save the day",
        s3_n: 'mood',
        s3_d: 'paintings and museums',
        s4_n: 'Home plants',
        s4_d: 'Lifehacks and care',
        s5_n: 'Easy home exercises',
        s5_d: 'Feel yourself at the gym',
      },
    },
    404: {
      ops: 'OOPS!',
      info: 'something went wrong...',
    },
    search: {
      title: 'Search videos on ViSaver',
      input_placeholder: 'Search videos on ViSaver',
      search_button: 'Search',
      landscape: 'Landscape',
      portrait: 'Portrait',
      count_title: 'Found {{countVideo}} video',
      count_title_zero: 'Found {{count}} video',
      count_title_one: 'Found {{count}} video',
      count_title_two: 'Found {{count}} videos',
      count_title_few: 'Found {{count}} videos',
      count_title_many: 'Found {{count}} videos',
      count_title_other: 'Found {{count}} videos',
      empty_input: 'Please, enter a search term',
      header: {
        pls: 'Playlists',
        lib_search: 'Library',
        saves: 'Saved',
      },
      popup: {
        info: 'Please rate our service',
        feedback_info: 'Thank you for your feedback - it helps us improve the service',
        placeholder: 'Write your comment here',
        send: 'Send',
        thanks: 'Thanks for your feedback!',
      },
      footer: {
        title: 'Video search has never been so convenient',
        target: 'Search results even for vague queries',
        target2: 'Simultaneous search across the largest video sources',
        target3: 'Instant access to the desired fragment',
        policy: 'Policy privacy',
      },
    },
    admin: {
      save: 'Save',
      delete_summary: 'Do you really want to delete this summary?',
      delete: 'Delete',
      timecodes_title: 'Section title',
      timecodes_text: 'Fragment`s description',
      add_answer: 'Add answer',
      add_section: 'Add section',
      title: 'Question title',
      question: 'Question',
      quiz_variant: 'Variants of the answer',
      search: {
        video: 'Search video',
        playlist: 'Search playlist',
      },
    },
    balance: {
      tariffs: 'Tariffs',
      referral: 'Referrals',
      history_buys: 'Order History',
      balance: 'Balance',
      costs: 'Transaction cost',
      cost_one_minut: 'Prices 1 minute in ViTokens',
      search: 'Search',
      base_tariff: 'Unlimited free search through videos from YouTube and VK!',
      youtube: 'YouTube search',
      vk: 'VK search',
      analytic: 'Basic analytics',
      search2: 'Basic',
      full_search: 'Search across all sources',
      transcript: 'Video transcription',
      analytics_full: 'Advanced analytics',
      search2_desc: 'All features of the Advanced Plan',
      search3: 'Advanced',
      func_prev_tariff: 'Search through videos from all sources',
      timestamps: 'Timestamps',
      summary: 'Automatic summary',
      search3_desc: 'Interactive thematic timestamps',
      search4: 'Professional',
      generation: 'Ability to create summaries',
      learn: 'Automatic accurate transcript',
      max_func: 'Automatic quiz creation based on video content',
      cost_ViTokens: 'Cost of ViTokens',
      cost_rub: 'Prices in rubles',
      ViTokens: 'ViTokens',
      for_token: 'per token',
      your_sale: 'Your savings',
      countTokens: 'Count tokens',
      buy: 'Pay',
      placeholder: 'Enter count of tokens',
      info_pay: 'The minimum count of tokens for purchase is 1',
      available: 'Available to you',
      add: 'Add',
      minut: 'min',
      sum: 'Total',
      info: '* Minutes from different plans do not add up. ViTokens are converted into minutes depending on the operation selected when uploading a video to the platform.',
      history_title: 'Purchase History',
      history_info: 'Your transactions',
      buy_plus: 'Top-up',
      buy_minus: 'Deduction',
    },
  },
  components: {
    info_public_pl_text: 'Timestamps, summaries, and quizzes are only available in private playlists',
    info_public_pl_search: 'Please wait. The search will become available after the video processing is complete',
    goBack: 'Back',
    copyIframe: 'Copy iframe',
    goHome: 'Back to homepage',
    goNext: 'Next',
    goSkip: 'Skip',
    copy_transcript: 'Copy',
    copy_timecodes: 'Copy all',
    title_transcript: 'Transcript video',
    title_summary: 'Key points',
    title_quiz: 'Knowledge check',
    download: 'Download',
    goStart: 'Start',
    pl_one: 'Playlist',
    pl_other: 'Playlists',
    pl_video_count: 'video',
    video_zero: 'Video',
    video_one: 'Video',
    video_two: 'Videos',
    video_few: 'Videos',
    video_many: 'Videos',
    video_other: 'Videos',
    search_in_pl: 'Search within videos in this playlist',
    search_in_video: 'Search within this video',
    transcript: 'Transcript',
    timecodes: 'Timestamps',
    timecodes_button: 'See less',
    timecodes_button_more: 'See more',
    timecodes_desc_more: '…more',
    quiz: 'Quiz',
    delete_quiz: 'Do you really want to delete this quiz?',
    delete_timecodes: 'Do you really want to delete these timestamps?',
    delete_summary_admin: 'Do you really want to delete this summary?',
    question_watch_video: 'Watch segment with answer',
    watch_in_video: 'View the answer in the video',
    incorrect_answer: 'Incorrect answer',
    correct_answer: 'Correct answer',
    question_button_video: 'Check answer',
    summary: 'Summary',
    files: 'Files',
    more: 'more',
    more_min: 'More',
    read_more: 'Read more',
    less: 'less',
    less_min: 'Less',
    form: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
      cur_password: 'Current password',
      new_password: 'New password',
      repeat_password: 'Repeat password',
      forgot_pass: 'Forgot password?',
      cancel: 'Cancel',
      continue: 'Continue',
      save: 'Save',
      send: 'Send',
      del: 'Delete',
      add: 'Add',
    },
    menu: {
      set_pl: 'Playlist settings',
      del_pl: 'Delete playlist',
      del_vl: 'Delete video',
      del_file_btn: 'Delete file',
      timecodes: 'Timestamps',
      summary: 'Summary',
      quiz: 'Quiz',
      transcript: 'Transcript',
    },
    breadcrumbsItems: {
      toHome: 'Home',
      toLib: 'Library',
      toPl: 'Playlist',
      toVideo: 'Video',
    },
  },
  modal: {
    reg: 'Register',
    reg_no_acc: 'Already have an account?',
    reg_info: 'By clicking "Register" you accept the',
    log_no_acc: 'No account yet?',
    add_v_to_pl: 'Add video to playlist',
    placeholder_select_create_pl: 'Select...',
    add_v_to_pl_insert: 'Select a video from the library',
    add_v_to_pl_no_v: 'There are no videos in the library',
    video_insert: 'Paste a link from YouTube, VK, Kinescope or Rutube',
    or_add_video: 'or',
    add_video_text1: 'Select a file',
    add_video_text2: 'in MP4, AVI, MKV, WEBM format or drag it here',
    download_file_status: ' uploaded',
    download_file_skip: 'Cancel',
    text_link: 'Link to the video',
    add_video_insert_text1: "For a file, you'll get timestamps, a summary, and a quiz.",
    add_video_insert_text2: 'Сontent search is possible when uploaded via a link.',
    video_format_invalid1: 'Add only MP4, AVI, MKV, WEBM files up to 5 GB.',
    video_format_invalid2: 'Files in other formats or larger sizes can be added via a link.',
    video_info_pl_1: 'To get timestamps, a summary, and a quiz for this video, ',
    video_info_pl_2: 'add it to a private playlist.',
    video_info_commercial_1: 'Video uploads are available only to commercial users. ',
    video_info_commercial_2: 'Please send your request to contact ',
    add_video_not_pl: "You don't have any playlists yet",
    video_insert_add_v: 'or upload a new one',
    video_choose: 'or choose a video from one of the sources',
    video_select_pl: 'Select the playlist the video will be saved in',
    video_no_select: 'Select the playlist',
    choose_tariffs_title: 'Choose operations:',
    ViTokens: 'ViТokens',
    recharge_ViTokens: 'Recharge',
    my_balance: 'Your balance:',
    category: 'Category',
    pl_name: 'Playlist name',
    pl_name_ph: 'QA tips',
    descr: 'Playlist description',
    descr_ph: 'Useful tips for everyday QA tasks',
    priv_set: 'Privacy settings',
    del_pl: 'Are you sure you want to delete this playlist?',
    del_acc: 'Are you sure you want to delete this account?',
    del_v: 'Are you sure you want to delete this video?',
    del_file_qw: 'Are you sure you want to delete this file?',
    verify_email: 'Verify email address',
    verify_email_no_send: 'Email not sent?',
    verify_email_resend: 'Resend',
    verify_email_err_acc: 'Account is already verified',
    verify_email_err: 'Activation error, contact support',
    verify_email_suc: 'Account verified successfully',
    verify_email_info: 'An email has been sent to you at {{email}} to confirm your credentials.',
    reset_password_info: 'An email has been sent to you at  {{email}} to reset your password.',
    res_pass: 'Reset password',
    res_email: 'Email Activation',
    change_password: 'Change password',
    suggest_video: 'Add videos to playlist using AI',
    suggest: 'Video selection',
  },
  notification: {
    welcome: 'Welcome!',
  },
  options: {
    psychology: 'Psychology',
    travel: 'Travel',
    work: 'Work',
    languages: 'Languages',
    education: 'Education',
    humor: 'Humor',
    useful: 'Useful',
    recipes: 'Recipes',
    children: 'Children',
    flowers: 'Flowers',
    hobby: 'Hobby',
    sport: 'Sport',
    music: 'Music',
    cinema: 'Cinema',
    public: 'Public',
    By_link: 'By link',
    private: 'Private',
  },
  error: {
    error: 'Error, try later',
    auth: 'Authorization Error',
    refresh: 'Session expired!',
    500: 'Server not responding',
    404: 'Resource not found',
    server: 'Server error',
    fetch: 'Connection error',
    acc_inactive: 'User account is inactive',
    req_field: 'Required field',
    reg: 'Registration failed!',
    reg_error_email: 'User with this email address already exists.',
    reg_error_username: 'A user with that username already exists.',
    log: 'Log in failed',
    req_field_login: 'No account found with the given credentials',
    set_pass: 'Failed to change password!',
    set_pass_reset_lint: 'The data from the link is no longer valid.',
    field_uniq: 'Name of the playlist must be unique.',
    incor_cred: 'Incorrect credentials',
    incor_email: 'Incorrect email',
    incor_password: 'Password must be contains letters and numbers',
    inverify_email: '{{email}} is not verified',
    min_char: 'Minimum {{length}} characters',
    max_char: 'Maximum {{length}} characters',
    incor_name: 'Incorrect name',
    no_save_ch: 'Changes not saved',
    try_l: 'Try later',
    size: 'Allowed maximum size is  {{size }}Mb',
    w_video: 'Log in to watch the video',
    add_v_not_sel: 'You have not selected a video',
    add_v_empty_link: 'Please insert video link',
    add_v_uncor_link: 'The server cannot process the link, please check the link',
    del_pl: 'Playlist not deleted',
    del_acc: 'Account not deleted',
    del_v: 'Video not deleted',
    del_file_err: 'File not deleted',
    del_timecodes: 'Failed to delete timestamps',
    save_timecodes: 'Failed to save timestamps',
    del_summary: 'Failed to delete summary',
    save_summary_err: 'Failed to save summary',
    save_quiz_err: 'Failed to save quiz',
    validate_quiz_err: 'Validation error',
    del_quiz_err: 'Failed to delete quiz',
    res_pass: 'Reset password',
    password_no_match: "Password doesn't match",
    change_video_title: 'Title cannot be empty',
    link: 'Link not copied',
    resend: 'Send again',
    err_timecodes: 'Unfortunately, an error has occurred. Please seek assistance',
    incorrect_video_link: "We don't support this link type yet.",
    incorrect_video_link1: 'Please paste the main video link.',
    incorrect_video_link_tiktok: "We don't support this source yet.",
    incorrect_video_link_tiktok1: 'Please paste a link from ',
    incorrect_video_link_tiktok2: 'or ',
    download_summary_error: 'The summary could not be downloaded, try again later',
  },
  loading: {
    load_timecodes: 'The timestamps are already being generated!',
    load_quiz: 'The test are already being generated!',
    load_summary: 'The summary are already being generated!',
    download_summary: 'Download summary',
    download_summary_loading: 'Downloading…',
    download_summary_done: 'Done!',
  },
  success: {
    cr_pl: 'Playlist “{{name}}” created successfully',
    ed_pl_name: '',
    ed_pl: '',
    logout: 'You logged out successfully!',
    registration: 'Your account has been successfully created!',
    link: 'Link copied!',
    data: 'Data changed successfully',
    format: 'Allowed file format {{format}}',
    add_video: 'Video successfully added to the “Videos” section',
    add_file: 'File successfully added to the “Files” section',
    add_video_pl: 'Video successfully added to playlist',
    add_videos_pl_n: 'Video(s) added to the “{{name}}” playlist',
    del_pl: 'Playlist deleted successfully',
    del_acc: 'Account successfully deleted',
    del_v: 'Video deleted successfully',
    del_file_ok: 'File deleted successfully',
    del_timecodes: 'Timestamps successfully deleted',
    save_timecodes: 'Timestamps successfully saved',
    save_quiz: 'Quiz successfully saved',
    del_quiz: 'Quiz successfully deleted',
    del_summary_success: 'Summary successfully deleted',
    save_summary: 'Summary successfully saved',
    verify_email: 'An email has been sent to you to confirm your credentials',
    set_password: 'Password changed successfully, please login',
    change_video_title: 'Title changed successfully',
  },
};
