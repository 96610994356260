import { forwardRef, memo } from 'react';

import { ButtonProps, ButtonStyled } from '@/components';
import { ButtonText } from '@/styles';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      themed = 'black',
      variant = 'default',
      width = 'auto',
      type = 'button',
      disabled = false,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <ButtonStyled
        themed={themed}
        width={width}
        variant={variant}
        type={type}
        disabled={disabled}
        className={className}
        {...props}
        ref={ref}
      >
        <ButtonText>{children}</ButtonText>
      </ButtonStyled>
    );
  },
);

Button.displayName = 'Button';

export default memo(Button);
