import { Suspense } from 'react';

import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';

import { Main, Wrapper } from '@/App.styled';
import { Container, FullScreenLoader } from '@/components';

export const EmbedLayout = () => {
  return (
    <Wrapper isMobile={isMobile}>
      <Main>
        <Suspense fallback={<FullScreenLoader />}>
          <Container>
            <Outlet />
          </Container>
        </Suspense>
      </Main>
    </Wrapper>
  );
};

export default EmbedLayout;
