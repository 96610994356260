import styled from 'styled-components';

export const LibraryRightSide = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    padding: 8px 0 8px 66px;
  }
`;

export const Library = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
`;
