import { lazy } from 'react';

export * from './Activate';
export { default as User } from './UserPage/UserPage';
export { default as Profile } from './ProfilePage/ProfilePage';
export { default as Onboarding } from './OnboardingPage/OnboardingPage';
export { default as NotFoundPage } from './NotFoundPage/NotFoundPage';
export const Password = lazy(() => import('./Password/Password'));
export * from './PolicyPage';
export * from './SuccessPaymentPage';
export * from './ReferalProgramPage/ReferalProgramPage';
export * from './ReferralRedirectPage/ReferralRedirectPage';
export * from './RedirectRegisterSocialPage/RedirectRegisterSocialPage';
export { default as ErrorPage } from './ErrorPage/ErrorPage';

export const OnboardingLazy = lazy(() => import('./OnboardingPage/OnboardingPage'));
export * from './VideoPage';
export * from './BalancePage';
export * from './Lending';
export * from './LibraryPage';
export const LendingLazy = lazy(() => import('./Lending/ui/Lending'));
