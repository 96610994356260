import { IframeWrapper } from './PlaylistVideoList.styled';

import { useVideos } from '../../model';
import { Container, NotVideoText, VideoGrid } from '../AllVideoList';

import { useEffect } from 'react';

import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { CopyIframeButton, FullScreenLoader, RemoveVideo, VideoCard, VideoFragmentCard } from '@/components';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import { usePagination } from '@/hooks';
import { PaginationWrapper } from '@/styles';
import { Playlist } from '@/types';

export type PlaylistVideoListProps = {
  playlistsList?: Playlist[];
};

export const PlaylistVideoList = ({ playlistsList }: PlaylistVideoListProps) => {
  const {
    searchInPlaylistError,
    searchVideosInPlaylist,
    isSearchInPlaylistLoading,
    makeSearch,
    isIframe,
    catchError,
    searchQueryValue,
    playlistId,
    createRoute,
  } = useVideos();

  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();
  const {
    data: playlist,
    isLoading: isPlaylistLoading,
    error: playlistError,
    isSuccess: playlistIsSuccess,
  } = playlistsAPI.useGetPlaylistByIdQuery({ id: playlistId ?? '', params: { page } }, { skip: !playlistId });

  useEffect(() => {
    if (playlistIsSuccess) {
      setCount(playlist.videos.count === 0 ? 1 : playlist.videos.count);
    } else {
      catchError(playlistError);
    }
  }, [playlist, playlistError]);

  useEffect(() => {
    catchError(playlistError || searchInPlaylistError);
  }, [searchInPlaylistError, playlistError]);

  const renderSearchResults = () => {
    if (searchQueryValue) {
      return (
        searchVideosInPlaylist &&
        searchVideosInPlaylist.map((fragment) =>
          fragment.cues.map((cue, i) => (
            <Link
              to={createRoute(fragment.publicId, cue.startsFrom)}
              key={`${fragment.publicId}-${i}`}
              {...(isIframe && { state: { playlistId: playlistId ?? '' } })}
            >
              <VideoFragmentCard fragment={cue} videoPreview={fragment.thumbnailUrl} />
            </Link>
          )),
        )
      );
    }

    return playlist?.videos.results.map((video) => (
      <VideoCard
        isIframe={isIframe}
        to={createRoute(video.publicId, video.startsFrom ?? 0)}
        playlistsList={playlistsList}
        key={video.publicId}
        movie={video}
      />
    ));
  };

  if (!playlist?.videos?.count && !isPlaylistLoading) {
    return <NotVideoText>Добавьте видео в плейлист</NotVideoText>;
  }

  return (
    <Container>
      {isPlaylistLoading && <FullScreenLoader />}
      <div style={{ marginBottom: '16px' }}>
        <SearchInVideoInput
          placeholder={`${isIframe ? 'Что ищем в видео?' : 'Какое видео ищем в Visaver?'}`}
          getSearch={makeSearch}
        />
      </div>
      {searchVideosInPlaylist &&
        searchVideosInPlaylist.length === 0 &&
        searchQueryValue &&
        !isSearchInPlaylistLoading && <NotVideoText>Результатов по запросу не найдено</NotVideoText>}
      <VideoGrid>{renderSearchResults()}</VideoGrid>
      <IframeWrapper>
        {!searchQueryValue && playlistId && playlistsList && playlistsList.length > 0 && (
          <CopyIframeButton playlistId={playlistId} />
        )}
      </IframeWrapper>
      {!searchQueryValue && !isPlaylistLoading && (
        <PaginationWrapper>
          <Pagination
            count={pages}
            page={page}
            color="primary"
            onChange={(e, newPage) => handleChangePage(e, newPage)}
          />
        </PaginationWrapper>
      )}
      <RemoveVideo />
    </Container>
  );
};

PlaylistVideoList.displayName = 'PlaylistVideoList';
