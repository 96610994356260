import { ActionButtonsPlaylist } from './ActionButtonsPlaylist';
import {
  PlaylistContent,
  PlaylistImage,
  PlaylistItem,
  PlaylistList,
  PlaylistName,
  PlaylistsContainer,
  PlaylistTitle,
} from './PlaylistsList.styled';

import { NavLink } from 'react-router-dom';

import { Playlist } from '@/types';

export type PlaylistsListPropsType = {
  playlistsList: Playlist[];
  isCollapsed: boolean;
  handlePlaylistSelect: (id?: string) => void;
};

export const PlaylistsList = ({ playlistsList, isCollapsed, handlePlaylistSelect }: PlaylistsListPropsType) => {
  return (
    <PlaylistsContainer>
      <PlaylistTitle isCollapsed={isCollapsed}>Плейлисты</PlaylistTitle>
      <PlaylistList>
        {playlistsList.map((playlist, ind) => (
          <NavLink key={`i-${ind}`} to={`/playlists/${playlist.publicId}`}>
            {({ isActive }) => (
              <PlaylistItem isActive={isActive} key={playlist.publicId} isCollapsed={isCollapsed}>
                <PlaylistContent isCollapsed={isCollapsed} onClick={() => handlePlaylistSelect(playlist.publicId)}>
                  <PlaylistImage>
                    {playlist.category.image && <img src={playlist.category.image} alt={playlist.title} />}
                  </PlaylistImage>
                  {!isCollapsed && <PlaylistName>{playlist.title}</PlaylistName>}
                </PlaylistContent>
                {!isCollapsed && (
                  <ActionButtonsPlaylist playlist={playlist} handlePlaylistSelect={handlePlaylistSelect} />
                )}
              </PlaylistItem>
            )}
          </NavLink>
        ))}
      </PlaylistList>
    </PlaylistsContainer>
  );
};
