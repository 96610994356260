import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { useAppSearchParams } from '@/hooks';
import { theme } from '@/styles';
import Global from '@/styles/global';
import { AppTheme } from '@/types';

export const DynamicThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentTheme, setCurrentTheme] = useState<AppTheme>(theme);
  const location = useLocation();
  const { getAppParam } = useAppSearchParams();

  useEffect(() => {
    const isIframe = location.pathname.includes('embed');
    const iframeTheme = getAppParam<string>('theme');
    console.log(iframeTheme);
    setCurrentTheme({
      ...theme,
      isIframe,
      iframeTheme: {
        ...theme.iframeTheme,
        current: iframeTheme as AppTheme['iframeTheme']['current'],
      },
    });
  }, [location]);

  return (
    <ThemeProvider theme={currentTheme}>
      <Global theme={currentTheme} />
      {children}
    </ThemeProvider>
  );
};
