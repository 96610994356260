import { AddButton, CollapseButton, PlaylistSidebarBottomButtons } from './SideBarBottomButtons.styled';

import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';

import { useActions } from '@/hooks';

export const SidebarBottomButtons = ({
  onCollapsedChange,
  isCollapsed,
}: {
  onCollapsedChange: (isCollapsed: boolean) => void;
  isCollapsed: boolean;
}) => {
  const { openModal } = useActions();

  return (
    <PlaylistSidebarBottomButtons>
      <AddButton
        onClick={() => openModal({ active: 'AddPlayList' })}
        isCollapsed={isCollapsed}
        title={isCollapsed ? 'Добавить плейлист' : undefined}
      >
        <Plus color={'white'} size={20} />
        {!isCollapsed && <span style={{ fontSize: '16px', color: 'white' }}>Добавить плейлист</span>}
      </AddButton>

      <CollapseButton
        isCollapsed={isCollapsed}
        onClick={() => onCollapsedChange(!isCollapsed)}
        title={isCollapsed ? 'Развернуть' : 'Свернуть'}
      >
        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
      </CollapseButton>
    </PlaylistSidebarBottomButtons>
  );
};
