import { forwardRef, MouseEvent, useImperativeHandle, useRef, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import { CheckIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

import {
  DropdownContainer,
  DropdownHeader,
  PlaylistButton,
  TitlePlaylistListItem,
} from '@/components/Shared/VideoDropdown/VideoDropDownMenu/VideoDropDownMenu.styled';
import { useActions, useMount } from '@/hooks';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { TextBlockScroll } from '@/pages/VideoPage/ui/Materials/TranscriptTab/TranscriptVideo.styled';
import { Playlist, Video, VideoInPlaylist } from '@/types';

interface VideoDropDownProps {
  playlistsList: Playlist[];
  video: Video | VideoInPlaylist;
  handleTogglePlaylist: (videoId: string, playlistId: string, isSelected: boolean) => void;
  onClose: () => void;
  isDropdownOpen: boolean;
  playlistsForVideo: string[];
}

export const VideoDropDownMenu = forwardRef<HTMLUListElement, VideoDropDownProps>(
  (
    { playlistsList, video: { publicId: videoId }, handleTogglePlaylist, isDropdownOpen, onClose, playlistsForVideo },
    ref,
  ) => {
    const [openPlaylistsList, setOpenPlaylistsList] = useState(true);
    const { openModal } = useActions();
    const { playlistId } = useParams();
    const dropdownRef = useRef<HTMLUListElement>(null);
    const { mounted } = useMount(isDropdownOpen);

    useImperativeHandle(ref, () => dropdownRef.current!);

    useOutsideClick({
      ref: dropdownRef,
      handler: onClose,
    });

    const deleteVideoHandler = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      openModal({ active: 'RemoveVideo', data: { id: videoId, playlistId: playlistId ?? '' } });
      onClose();
    };

    const handlePlaylistToggle = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setOpenPlaylistsList((prev) => !prev);
    };

    const itemsPlList = playlistsList.map((playlist) => {
      const isSelected = playlistsForVideo.includes(playlist.publicId);

      return {
        playlistId: playlist.publicId,
        title: playlist.title,
        icon: isSelected ? <CheckIcon color="#c084fc" width={16} height={16} /> : null,
        onClick: () => handleTogglePlaylist(videoId, playlist.publicId, isSelected),
      };
    });

    if (mounted) {
      return (
        <DropdownContainer ref={dropdownRef} sx={{ position: 'absolute', zIndex: 10, pt: 0, pb: 0 }}>
          <PlaylistButton onClick={handlePlaylistToggle}>
            <DropdownHeader primary="Добавить в плейлист" />
            {openPlaylistsList ? <ExpandMore /> : <ExpandLess />}
          </PlaylistButton>
          <Collapse in={openPlaylistsList} timeout="auto" unmountOnExit>
            <TextBlockScroll style={{ height: '115px', paddingRight: '6px' }}>
              {itemsPlList.map((item) => (
                <ListItemButton onClick={item.onClick} style={{ padding: '2px 6px 3px 16px' }} key={item.playlistId}>
                  <TitlePlaylistListItem primary={item.title} />
                  {item.icon}
                </ListItemButton>
              ))}
            </TextBlockScroll>
          </Collapse>
          <PlaylistButton onClick={deleteVideoHandler} sx={{ borderTop: '1px solid rgba(168, 85, 247, 0.3)' }}>
            <DropdownHeader primary={playlistId ? 'Удалить видео из плейлиста' : 'Удалить видео'} />
          </PlaylistButton>
        </DropdownContainer>
      );
    }
    return null;
  },
);

VideoDropDownMenu.displayName = 'VideoDropDownMenu';
