import { StyledInputV2 } from './SearchInVideoInputV2.styled';

import { useEffect, useRef } from 'react';

import Search from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { IconWrapper, Wrapper } from '@/components/Shared/SearchInVideoInput/SearchInVideoInput.styled';
import { useDebounce, useIframe } from '@/hooks';

interface SearchInVideoInputV2Props {
  getSearch: (value: string) => Promise<void>;
  disabled?: boolean;
  placeholder?: string;
}

export const SearchInVideoInputV2 = ({ getSearch, disabled = false, placeholder }: SearchInVideoInputV2Props) => {
  const { t } = useTranslation('components');
  const [param, setParam] = useSearchParams();
  const search = param.get('search') || '';
  const { isIframe, iframeTheme } = useIframe();

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const data = searchInputRef.current?.value || '';

    if (data) {
      getSearch(data);
    }
  }, []);

  const makeSearch = useDebounce(() => {
    const data = searchInputRef.current?.value || '';
    if (data) {
      setParam((prev) => {
        prev.set('search', data);
        return prev;
      });
      getSearch(data);
    } else {
      setParam((prev) => {
        prev.delete('search');
        return prev;
      });
    }
  }, 500);

  const onSearch = () => {
    makeSearch();
  };

  return (
    <Wrapper>
      <StyledInputV2
        placeholder={placeholder ?? t('search_in_video')}
        ref={searchInputRef}
        onChange={onSearch}
        defaultValue={search}
        disabled={disabled}
        icon={
          <IconWrapper>
            <Search style={{ color: isIframe && iframeTheme === 'cintera' ? 'rgba(148, 54, 89, 0.8)' : '#a855f7' }} />
          </IconWrapper>
        }
      />
    </Wrapper>
  );
};
