import styled from 'styled-components';

import { IconWrapper } from '@/pages/ReferalProgramPage';

export const MessengersWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const MessengerIconWrapper = styled(IconWrapper)`
  background-color: #110b30;
  border-radius: 9999px;
  align-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
