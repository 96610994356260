import { IconWrapper, Wrapper } from './RegisterOrLoginWithSocial.styled';

import * as VKID from '@vkid/sdk';

import { VkSocialIcon } from '@/components/SvgIcons/VkSocialIcon';
import { YandexSocialIcon } from '@/components/SvgIcons/YandexSocialIcon';
import { VK_AUTH_CLIENT_ID, YANDEX_AUTH_CLIENT_ID } from '@/constants';
import { useMatchMedia } from '@/hooks';
import { generateCodeVerifier } from '@/utils/generateCodeVerifier';

export const RegisterOrLoginWithSocial = () => {
  const isMobile = useMatchMedia('(max-width: 480px)');

  const handleVKLogin = async () => {
    const codeVerifier = generateCodeVerifier();
    localStorage.setItem('vk_code_verifier', codeVerifier);

    VKID.Config.init({
      app: VK_AUTH_CLIENT_ID,
      redirectUrl: `${import.meta.env.VITE_BASE_URL}/auth/vk/callback`,
      state: 'state',
      codeVerifier: codeVerifier,
      responseMode: VKID.ConfigResponseMode.Callback,
      source: VKID.ConfigSource.LOWCODE,
      scope: 'email phone',
      mode: VKID.ConfigAuthMode.Redirect,
    });

    VKID.Auth.login();
  };

  const handleYandexLogin = async () => {
    const yandexParams = new URLSearchParams({
      response_type: 'code',
      client_id: YANDEX_AUTH_CLIENT_ID,
      redirect_uri: `${import.meta.env.VITE_BASE_URL}/auth/yandex/callback`,
    });

    const yandexAuthUrl = `https://oauth.yandex.ru/authorize?${yandexParams.toString()}`;
    window.location.href = yandexAuthUrl;
  };

  // const handleMailLogin = () => {
  //   const params = new URLSearchParams({
  //     client_id: import.meta.env.VITE_VK_AUTH_CLIENT_ID,
  //     response_type: 'code',
  //     redirect_uri: `${import.meta.env.VITE_BASE_URL}/auth/mail_ru/callback`,
  //     scope: 'userinfo',
  //     state: 'mailru_auth',
  //   });
  //
  //   window.location.href = `https://oauth.mail.ru/login?${params.toString()}`;
  // };

  const items = [
    {
      id: 1,
      icon: YandexSocialIcon,
      text: 'Войти с помощью Yandex',
      onClick: () => handleYandexLogin(),
    },
    {
      id: 2,
      icon: VkSocialIcon,
      text: 'Войти с помощью VK',
      onClick: () => handleVKLogin(),
    },
    // {
    //   id: 3,
    //   icon: MailSocialIcon,
    //   onClick: () => handleMailLogin(),
    // },
    // {
    //   id: 4,
    //   icon: OKSocialIcon,
    //   onClick: () => handleVKLogin(),
    // },
  ];

  return (
    <Wrapper>
      {items.map(({ id, icon: Icon, onClick, text }) => (
        <IconWrapper key={id} onClick={onClick}>
          <Icon />
          {!isMobile && text}
        </IconWrapper>
      ))}
    </Wrapper>
  );
};
