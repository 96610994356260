import styled from 'styled-components';

export const ContainerStyled = styled.div`
  width: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? '100%' : '90%')};
  max-width: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? '100%' : '380px')};
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    padding: 0;
    width: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? '100%' : '95%')};
  }

  @media (min-width: 480px) {
    max-width: 480px;
  }
  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    //max-width: 1024px;
    max-width: 100%;
  }
  @media (min-width: 1280px) {
    max-width: 100%;
  }
`;
