import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';

import { LabelText, SecondaryText } from '@/styles';

export const UploadContainer = styled.div`
  position: relative;
  margin: 80px auto;
  width: 440px;
  background-color: #251a56;
  border-radius: 1rem;
  padding: 2rem;
  border: 1px solid rgba(128, 0, 128, 0.2);

  @media screen and (max-width: 645px) {
    width: 100%;
  }
`;

export const DoneLoadingWrapper = styled.div`
  //margin-right: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const ContainerDoneLoading = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 16px;
  background-color: #251a56;
  border-radius: 12px;
`;

export const ProgressInfoBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OuterContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 17px;
`;

export const GradientBackground = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, #6b5bff, #ff6b81);
  border-radius: 50%;
  filter: blur(8px);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.7;
    }
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  background-color: #251a56; /* Цвет фона */
  border-radius: 50%; /* Полное закругление */
  padding: 16px; /* Внутренние отступы (4 * 4) */
`;
export const TextBlockLoading = styled.div``;

export const SecondaryTextLoading = styled(SecondaryText)`
  font-size: 21px;
  font-weight: normal;
  color: white;
  margin-bottom: 5px;
`;

export const LabelTextPurple = styled(LabelText)`
  font-size: 15px;
  font-weight: normal;
  color: #d8b4fe;
  margin-bottom: 5px;
`;

export const LabelTextPurple2 = styled(LabelTextPurple)`
  font-size: 15px;
  color: #c084fc;
`;

export const LabelTextPurple3 = styled(LabelTextPurple)`
  font-size: 13px;
  color: #d8b4fecc;
`;
export const StyledLinearProgress = styled(LinearProgress)(() => ({
  borderRadius: '5px',
  background: '#1a1145 !important',
  height: '10px !important',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    background: 'linear-gradient(to right, rgba(107, 70, 193) 0%, rgba(255, 105, 180) 100%)',
  },
}));

export const SecondaryTextStyled = styled(SecondaryText)(() => ({
  ':hover': {
    cursor: 'pointer',
    color: '#E4E4FF',
  },
}));
