import styled from 'styled-components';

import MyInput from '@/components/Shared/MyInput/MyInput';

export const StyledInputV2 = styled(MyInput)`
  & input {
    width: 100%;

    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'transparent'
        : isIframe && iframeTheme.current === 'cintera'
        ? '#2e2e30'
        : '#1a1145'};

    border: 1px solid
      ${({ theme: { isIframe, iframeTheme } }) =>
        isIframe && iframeTheme.current === 'light'
          ? 'rgba(168, 85, 247, 0.3)'
          : isIframe && iframeTheme.current === 'cintera'
          ? 'rgb(148 54 89 / 0.3)'
          : 'rgba(168, 85, 247, 0.3)'};
    border-radius: 0.75rem;
    padding: 14px 16px 14px 48px;
    font-size: 16px;

    color: ${({ theme: { iframeTheme, isIframe } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(26,17,69)'
        : isIframe && iframeTheme.current === 'cintera'
        ? '#E4E4FF'
        : '#a78bfa'};
    transition: all 300ms;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    ::placeholder {
      color: ${({ theme: { iframeTheme, isIframe } }) =>
        isIframe && iframeTheme.current === 'light'
          ? 'rgba(26,17,69,0.6)'
          : isIframe && iframeTheme.current === 'cintera'
          ? 'rgba(228,228,255,0.4)'
          : '#a78bfa'};
    }

    :focus,
    :hover {
      outline: none;
      border: 1px solid
        ${({ theme: { isIframe, iframeTheme } }) =>
          isIframe && iframeTheme.current === 'light'
            ? '#a855f7'
            : isIframe && iframeTheme.current === 'cintera'
            ? 'rgb(148 54 89)'
            : '#a855f7'};

      box-shadow: 0 20px 25px -5px ${({ theme: { isIframe, iframeTheme } }) => (isIframe && iframeTheme.current === 'light' ? 'rgba(168, 85, 247, 0.1)' : isIframe && iframeTheme.current === 'cintera' ? 'none' : 'rgba(168, 85, 247, 0.1)')},
        0 8px 10px -6px ${({ theme: { isIframe, iframeTheme } }) => (isIframe && iframeTheme.current === 'light' ? 'rgba(168, 85, 247, 0.1)' : isIframe && iframeTheme.current === 'cintera' ? 'none' : 'rgba(168, 85, 247, 0.1)')};
    }
  }
`;
