import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const VideoGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  width: 100%;
  height: max-content;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const NotVideoText = styled.span`
  display: flex;
  justify-content: center;
  color: ${({ theme }) =>
    theme.isIframe && theme.iframeTheme.current === 'light'
      ? '#251a56'
      : theme.isIframe && theme.iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : 'rgba(216, 180, 254, 0.5)'};
  font-size: 16px;
  margin-top: 15%;
`;
