import AvatarWithMenu from './AvatarMenu/AvatarWithMenu';
import { BalanceButton, BalanceText, ButtonsWrapper, HeaderStyled, Inner, LinkHeader } from './Header.styled';

import { Button, Container } from '../Shared';
import PlusBig from '../SvgIcons/PlusBigIcon';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usersAPI } from '@/api';
import { useActions, useAppSelector, useMatchMedia } from '@/hooks';
import logo from '@/pages/Lending/ui/Header/logo.svg';
import visaver_logo from '@/pages/Lending/ui/Header/visaver.png';
import { ModalType } from '@/store/slices/modalsSlice';

const Header = () => {
  const { openModal } = useActions();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  // const [searchInputMobileIsOpen, setSearchInputMobileIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation('header');
  const { data: user } = usersAPI.useGetUserMeQuery();
  const isMobile = useMatchMedia('(max-width: 768px)');

  // const inputMobileCloseHandler = () => {
  //   setSearchInputMobileIsOpen(false);
  //   resetSearch();
  // };

  const openModalHandler = (modal: ModalType) => {
    if (isAuth) {
      openModal({ active: modal });
    } else {
      openModal({ active: 'login' });
    }
  };

  return (
    <HeaderStyled>
      <Container>
        <Inner>
          <LinkHeader to="/">
            <img src={logo} alt="ViSaver Logo" width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
            <img src={visaver_logo} alt="ViSaver" width={150} />
          </LinkHeader>

          {/*<InputWrapper isOpen={searchInputMobileIsOpen}>*/}
          {/*  <SearchInput />*/}
          {/*  <Button variant="withIcon" onClick={inputMobileCloseHandler} themed="transparent">*/}
          {/*    <CrossIcon />*/}
          {/*  </Button>*/}
          {/*</InputWrapper>*/}
          <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
            <ButtonsWrapper>
              {/*<SearchButton onClick={() => setSearchInputMobileIsOpen(true)} />*/}
              {isAuth && user && (
                <BalanceButton onClick={() => navigate('/balance')}>
                  <BalanceText>{user.balance} ₽</BalanceText>
                </BalanceButton>
              )}
              <Button themed="black" onClick={() => openModalHandler('AddVideo')}>
                {t('addVideo')}
              </Button>
              {/*<Button themed="black" onClick={() => openModalHandler('AddPlayList')}>*/}
              {/*  {t('createPlaylist')}*/}
              {/*</Button>*/}
              <Button variant="withIcon" themed="transparent" onClick={() => openModalHandler('AddMobile')}>
                <PlusBig />
              </Button>
            </ButtonsWrapper>
            <AvatarWithMenu />
          </div>
        </Inner>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
